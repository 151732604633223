import {
  ADD_CATEGORY_ERROR,
  ADD_CATEGORY_STARTED,
  ADD_CATEGORY_SUCCESS,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY_STARTED,
  DELETE_CATEGORY_SUCCESS,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_STARTED,
  GET_CATEGORIES_SUCCESS,
  UPDATE_CATEGORY_ERROR,
  UPDATE_CATEGORY_STARTED,
  UPDATE_CATEGORY_SUCCESS,
  RESET_CATEGORY_EDITING
} from './Categories.types'

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  categories
})

export const getCategoriesStarted = () => ({
  type: GET_CATEGORIES_STARTED
})

export const getCategoriesError = reason => ({
  type: GET_CATEGORIES_ERROR,
  reason
})

export const updateCategorySuccess = updatedCategoryObj => ({
  type: UPDATE_CATEGORY_SUCCESS,
  category: updatedCategoryObj
})

export const updateCategoryStarted = id => ({
  type: UPDATE_CATEGORY_STARTED,
  id
})

export const updateCategoryError = reason => ({
  type: UPDATE_CATEGORY_ERROR,
  reason
})

export const deleteCategorySuccess = id => ({
  type: DELETE_CATEGORY_SUCCESS,
  id
})

export const deleteCategoryStarted = id => ({
  type: DELETE_CATEGORY_STARTED,
  id
})

export const deleteCategoryError = reason => ({
  type: DELETE_CATEGORY_ERROR,
  reason
})

export const createCategorySuccess = category => ({
  type: ADD_CATEGORY_SUCCESS,
  category
})

export const createCategoryStarted = category => ({
  type: ADD_CATEGORY_STARTED,
  category
})

export const createCategoryError = reason => ({
  type: ADD_CATEGORY_ERROR,
  reason
})

export const resetCategoryEditing = () => ({
  type: RESET_CATEGORY_EDITING
})
