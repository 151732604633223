import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  makeStyles,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ArrowRight as ArrowRightIcon, Search as SearchIcon, Star as PopularIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link as RouterLink } from 'react-router-dom'
import getInitials from 'src/utils/getInitials'
import { localizedPropertySearch, money, reorderListByIndex, t } from 'src/utils'
import { getSelectedLanguage } from 'src/store/general/selectors'
import translationsDishes from 'src/translations/dishes'
import useBrandAppPreferences from '../../../hooks/useBrandAppPreferences'
import { StyledBackdrop } from '../../clientui/styledComponents'
import { selectAllDishes } from '../../../store/dishes/Dishes.selectors'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import LoadingSpinner from '../../../components/UI/LoadingSpinner'

const sortOptions = translations => {
  return [
    {
      value: 'name|asc',
      label: translations.alphabetically
    },
    {
      value: 'name|desc',
      label: translations.alphabeticallyReverse
    },
    {
      value: 'id|desc',
      label: translations.byAddingFromRecent
    },
    {
      value: 'id|asc',
      label: translations.byAddingFromFirst
    },
    {
      value: 'price|desc',
      label: translations.byPriceFromExpensive
    },
    {
      value: 'price|asc',
      label: translations.byPriceFromCheap
    },
    {
      value: 'popular',
      label: translations.byPopular
    }
  ]
}

const applyFilters = (dishes, query) => {
  return dishes.filter(dish => {
    let matches = true

    if (query) {
      const properties = ['name']
      let containsQuery = false

      properties.forEach(property => {
        if (localizedPropertySearch(dish[property], query)) {
          containsQuery = true
        }
      })

      if (!containsQuery) {
        matches = false
      }
    }

    return matches
  })
}

const descendingComparator = (a, b, orderBy) => {
  let orderByWrap = param => param
  if (orderBy === 'name') orderByWrap = param => t(param, 'ru')
  if (orderByWrap(b[orderBy]) < orderByWrap(a[orderBy])) {
    return -1
  }

  if (orderByWrap(b[orderBy]) > orderByWrap(a[orderBy])) {
    return 1
  }

  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const applySort = (dishes, sort) => {
  const [orderBy, order] = sort.split('|')
  const comparator = getComparator(order, orderBy)
  const stabilizedThis = dishes.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])

    if (order !== 0) return order

    return a[1] - b[1]
  })

  return stabilizedThis.map(el => el[0])
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    overflow: 'visible'
  },
  queryField: {
    width: 300
  },
  nativeRow: {
    background: '#fff'
  },
  filterGroup: {
    marginLeft: theme.spacing(2)
  },
  bulkActions: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    backgroundColor: theme.palette.background.default,
    '& Button': {
      marginLeft: theme.spacing(2)
    }
  },
  avatar: {
    height: 44,
    width: 44,
    marginRight: theme.spacing(1)
  },
  filterButton: {
    height: 56,
    marginRight: theme.spacing(2)
  },
  dishTitle: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const Results = () => {
  const selectedLanguage = useSelector(getSelectedLanguage)
  const dishes = useSelector(selectAllDishes)
  const translations = translationsDishes[selectedLanguage]
  const classes = useStyles()
  const [selectedDishes, setSelectedDishes] = useState([])
  const [query, setQuery] = useState('')
  const [filterBy, setFilterBy] = useState('all')
  const [sort, setSort] = useState(sortOptions(translations)[3].value)

  const [
    { appPreferences, loading: preferencesLoading, updatingState: updatePreferencesState },
    { addDishesToPopular, removeDishesFromPopular }
  ] = useBrandAppPreferences()

  const handleQueryChange = event => {
    event.persist()
    setQuery(event.target.value)
  }

  const handleSortChange = event => {
    event.persist()
    setSort(event.target.value)
  }

  const handleSelectAll = event => {
    setSelectedDishes(event.target.checked ? dishes.map(dish => dish.id) : [])
  }

  const handleSelectOne = (event, dishId) => {
    if (!selectedDishes.includes(dishId)) {
      setSelectedDishes(prevSelected => [...prevSelected, dishId])
    } else {
      setSelectedDishes(prevSelected => prevSelected.filter(id => id !== dishId))
    }
  }

  const resetSelected = () => {
    setSelectedDishes([])
  }

  const handleAddToPopular = useCallback(() => {
    addDishesToPopular(selectedDishes)
    resetSelected()
  }, [addDishesToPopular, selectedDishes])

  const handleRemoveFromPopular = useCallback(() => {
    removeDishesFromPopular(selectedDishes)
    resetSelected()
  }, [removeDishesFromPopular, selectedDishes])

  const filteredDishes = applyFilters(dishes, query)

  const sortedDishes =
    sort !== 'popular'
      ? applySort(filteredDishes, sort)
      : filteredDishes.sort((a, b) => {
          return +appPreferences.popularDishesIds?.includes(b.id) - +appPreferences.popularDishesIds?.includes(a.id)
        })

  const enableBulkOperations = selectedDishes.length > 0
  const selectedSome = selectedDishes.length > 0 && selectedDishes.length < dishes.length
  const selectedAll = selectedDishes.length === dishes.length
  const readyToRemoveFromPopular = selectedDishes.every(id => appPreferences.popularDishesIds?.includes(id))

  if (!dishes.length) {
    return null
  }

  return (
    <Card className={classes.root}>
      <Divider />
      <Box p={2} minHeight={56} display='flex' alignItems='center'>
        <TextField
          className={classes.queryField}
          disabled={filterBy !== 'all'}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SvgIcon fontSize='small' color='action'>
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            )
          }}
          onChange={handleQueryChange}
          placeholder={translations.findDish}
          value={query}
          variant='outlined'
        />
        <Box flexGrow={1} />
        <TextField
          label={translations.sort}
          name='sort'
          onChange={handleSortChange}
          select
          disabled={filterBy !== 'all'}
          SelectProps={{ native: true }}
          value={sort}
          variant='outlined'
        >
          {sortOptions(translations).map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
        <RadioGroup
          aria-label='filter'
          value={filterBy}
          name='filter-group'
          className={classes.filterGroup}
          onChange={e => setFilterBy(e.target.value)}
        >
          <FormControlLabel value='all' control={<Radio size='small' />} label={translations.all} />
          <FormControlLabel value='popular' control={<Radio size='small' />} label={translations.byPopular} />
        </RadioGroup>
      </Box>
      {enableBulkOperations && (
        <div className={classes.bulkActions}>
          <Checkbox checked={selectedAll} indeterminate={selectedSome} onChange={handleSelectAll} />
          <Button
            variant='outlined'
            size='small'
            color='primary'
            startIcon={<PopularIcon />}
            onClick={readyToRemoveFromPopular ? handleRemoveFromPopular : handleAddToPopular}
          >
            {readyToRemoveFromPopular ? translations.removeFromPopular : translations.addToPopular}
          </Button>
        </div>
      )}
      <div>
        <Box minWidth={700}>
          <StyledBackdrop open={preferencesLoading || updatePreferencesState.loading}>
            <CircularProgress variant='indeterminate' size='3rem' />
          </StyledBackdrop>
          <Table size='small'>
            {!enableBulkOperations && (
              <TableHead>
                <TableRow>
                  <TableCell padding='checkbox'>
                    {filterBy === 'all' && (
                      <Checkbox checked={selectedAll} indeterminate={selectedSome} onChange={handleSelectAll} />
                    )}
                  </TableCell>
                  <TableCell width='100%'>{translations.name}</TableCell>
                  <TableCell align='right'>{translations.price}</TableCell>
                  {/* <TableCell>В меню</TableCell> */}
                  <TableCell />
                </TableRow>
              </TableHead>
            )}
            {filterBy === 'all' && (
              <TableBody>
                <RenderDishList
                  {...{
                    paginatedDishes: sortedDishes,
                    handleSelectOne,
                    classes,
                    selectedDishes,
                    popularDishes: appPreferences?.popularDishesIds || []
                  }}
                />
              </TableBody>
            )}
            {filterBy === 'popular' && (
              <PopularDishList
                {...{
                  paginatedDishes: sortedDishes,
                  handleSelectOne,
                  classes,
                  popularDishes: appPreferences?.popularDishesIds || [],
                  selectedDishes
                }}
              />
            )}
          </Table>
        </Box>
      </div>
    </Card>
  )
}

const PopularDishList = ({ paginatedDishes, popularDishes, classes, selectedDishes, handleSelectOne }) => {
  const [{ updatingState }, { updatePopularDishes }] = useBrandAppPreferences()
  const listRef = useRef(null)
  const [currentPopularDishes, setCurrentPopularDishes] = useState([])

  const dishes = useMemo(
    () => currentPopularDishes.map(id => paginatedDishes.find(dish => dish.id === id)).filter(dish => dish),
    [currentPopularDishes, paginatedDishes]
  )

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return
      }

      if (result.destination.index === result.source.index) {
        return
      }

      const reorderedDishes = reorderListByIndex(currentPopularDishes, result.source.index, result.destination.index)
      setCurrentPopularDishes(reorderedDishes)
      updatePopularDishes(reorderedDishes)
    },
    [currentPopularDishes, updatePopularDishes]
  )

  useEffect(() => {
    setCurrentPopularDishes(popularDishes)
  }, [popularDishes])

  return (
    <>
      <LoadingSpinner loading={updatingState.loading} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='popular'>
          {provided => (
            <TableBody
              ref={el => {
                listRef.current = el
                provided.innerRef(el)
              }}
              {...provided.droppableProps}
              style={{
                minHeight: listRef.current ? `${listRef.current.clientHeight}px` : null,
                background: '#fff'
              }}
            >
              {dishes?.map((dish, index) => (
                <Draggable key={dish.id} draggableId={`${dish.id}`} index={index}>
                  {provided => (
                    <RenderDish
                      native={true}
                      dragProps={provided}
                      {...{ dish, handleSelectOne, classes, selectedDishes, isPopular: true }}
                    />
                  )}
                </Draggable>
              ))}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

const RenderDishList = ({ paginatedDishes, handleSelectOne, classes, selectedDishes, popularDishes }) => {
  return paginatedDishes.map(dish => (
    <RenderMemoDish
      key={dish.id}
      {...{ dish, handleSelectOne, classes, selectedDishes, isPopular: popularDishes.includes(dish.id) }}
    />
  ))
}

const RenderDish = ({ dish, handleSelectOne, classes, selectedDishes, isPopular, native, dragProps }) => {
  const isDishSelected = selectedDishes.includes(dish.id)
  const selectedLanguage = useSelector(getSelectedLanguage)

  const Wrapper = native ? 'tr' : TableRow
  return (
    <Wrapper
      draggable={true}
      key={dish.id}
      className={native && classes.nativeRow}
      {...(!native
        ? {
            hover: true,
            selected: isDishSelected
          }
        : {})}
      {...(dragProps
        ? {
            ref: dragProps.innerRef,
            ...dragProps.draggableProps,
            ...dragProps.dragHandleProps
          }
        : {})}
    >
      <TableCell padding='checkbox'>
        <Checkbox checked={isDishSelected} onChange={event => handleSelectOne(event, dish.id)} value={isDishSelected} />
      </TableCell>
      <TableCell width='100%'>
        <Box width='100%' display='flex' alignItems='center'>
          <Avatar variant='square' className={classes.avatar} src={`/${dish.img}`}>
            {getInitials(t(dish.name, selectedLanguage))}
          </Avatar>
          <div>
            <Typography color='inherit' variant='h6' className={classes.dishTitle}>
              {isPopular && <PopularIcon size={22} />}
              &nbsp;
              {t(dish.name, selectedLanguage)}
            </Typography>
            <Typography variant='body2' color='textSecondary'>
              {dish.label ? t(dish.label, selectedLanguage) : t(dish.name, selectedLanguage)}
            </Typography>
          </div>
        </Box>
      </TableCell>
      <TableCell align='right'>{money(dish.price)}₴</TableCell>
      <TableCell align='right'>
        <IconButton component={RouterLink} to={`/app/dishes/${dish.id}`}>
          <SvgIcon fontSize='small'>
            <ArrowRightIcon />
          </SvgIcon>
        </IconButton>
      </TableCell>
    </Wrapper>
  )
}

function arePropsEqual(prevProps, nextProps) {
  /*
      return true if passing nextProps to render would return
      the same result as passing prevProps to render,
      otherwise return false
      */
  return (
    nextProps.isPopular === prevProps.isPopular &&
    nextProps.selectedDishes.includes(nextProps.dish.id) === prevProps.selectedDishes.includes(prevProps.dish.id)
  )
}

const RenderMemoDish = memo(RenderDish, arePropsEqual)

export default Results
