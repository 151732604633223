import { getActiveThemeStarted, getActiveThemeSuccess, getActiveThemeError } from './Themes.actions'
import { getCurrentThemeQuery } from 'src/api'

export const getActiveThemeThunk = () => async dispatch => {
  try {
    dispatch(getActiveThemeStarted())
    const resp = await getCurrentThemeQuery()
    if (resp) {
      dispatch(getActiveThemeSuccess(resp))
    }
  } catch (err) {
    dispatch(getActiveThemeError(err))
  }
}
