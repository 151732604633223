import {
  GET_TAGS_ERROR,
  GET_TAGS_SUCCESS,
  GET_TAGS_STARTED,
  ADD_TAG_ERROR,
  ADD_TAG_SUCCESS,
  ADD_TAG_STARTED,
  DELETE_TAG_ERROR,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_STARTED,
  UPDATE_TAG_ERROR,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_STARTED,
  RESET_RESULT
} from './tags.types'

import objFromArray from 'src/utils/objFromArray'

const initialState = {
  entities: {},
  loading: false,
  lastEditedTag: null,
  lastCreatedTag: null,
  errorMessage: null
}

const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAGS_SUCCESS: {
      return {
        ...state,
        entities: objFromArray(action.tags),
        loading: false
      }
    }
    case GET_TAGS_STARTED: {
      return {
        ...state,
        loading: true,
        errorMessage: null
      }
    }
    case GET_TAGS_ERROR: {
      return {
        ...state,
        loading: false,
        errorMessage: action.reason
      }
    }
    case UPDATE_TAG_SUCCESS: {
      const updatedTags = {
        ...state.entities,
        [action.tag.id]: {
          ...action.tag
        }
      }
      return {
        ...state,
        lastEditedTag: action.tag.id,
        loading: false,
        entities: updatedTags
      }
    }
    case UPDATE_TAG_STARTED: {
      return {
        ...state,
        loading: true,
        lastEditedTag: null,
        errorMessage: null
      }
    }
    case UPDATE_TAG_ERROR: {
      return {
        ...state,
        loading: false,
        errorMessage: action.reason
      }
    }
    case ADD_TAG_SUCCESS: {
      return {
        ...state,
        loading: false,
        lastCreatedTag: action.tag.id,
        entities: {
          ...state.entities,
          [action.tag.id]: action.tag
        }
      }
    }
    case ADD_TAG_STARTED: {
      return {
        ...state,
        loading: true,
        lastCreatedTag: null,
        errorMessage: null
      }
    }
    case ADD_TAG_ERROR: {
      return {
        ...state,
        loading: false,
        errorMessage: action.reason
      }
    }
    case DELETE_TAG_SUCCESS: {
      const updatedTags = { ...state.entities }
      delete updatedTags[action.id]

      return {
        ...state,
        loading: false,
        entities: updatedTags
      }
    }
    case DELETE_TAG_STARTED: {
      return {
        ...state,
        loading: true,
        errorMessage: null
      }
    }
    case DELETE_TAG_ERROR: {
      return {
        ...state,
        loading: false,
        errorMessage: action.reason
      }
    }
    case RESET_RESULT: {
      return {
        ...state,
        loading: false,
        errorMessage: null,
        lastCreatedTag: null,
        lastEditedTag: null
      }
    }

    default:
      return state
  }
}

export default tagsReducer
