import jwtDecode from 'jwt-decode'
import React, { createContext, useEffect, useReducer } from 'react'
import SplashScreen from 'src/components/SplashScreen'
import axios from 'src/utils/axios'
import { config } from 'src/constants'
import { fetchSelfProfile } from 'src/api'
import { useDispatch, useSelector } from '../store'
import { getCurrentBrand } from '../store/currentBrand/thunkActions'
const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
}

const isValidToken = accessToken => {
  if (!accessToken) {
    return false
  }

  const decoded = jwtDecode(accessToken)
  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

const setSession = accessToken => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken)
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    axios.defaults.headers.common.Authorization = `Basic ${accessToken}`
  } else {
    localStorage.removeItem('accessToken')
    delete axios.defaults.headers.common.Authorization
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      }
    }
    case 'LOGIN': {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user
      }
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      }
    }
    case 'REGISTER': {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user
      }
    }
    default: {
      return { ...state }
    }
  }
}

const ENCODING = 'base64'

function token(user, pass) {
  return base64([user, pass].join(':'))
}

function base64(string) {
  return new Buffer(string).toString(ENCODING)
}

const authHeader = {
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve()
})

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState)
  const rootDispatch = useDispatch()

  const login = async (email, password) => {
    // const str = token(email, password)
    // console.log(str)
    // console.log(config, window.busytable)
    // setLogin(str)
    // window.localStorage.setItem('accessToken', str)
    const response = await axios
      .get(`${config.API_URL3}/backoffice/${window.busytable.brandSlug}/optionsets`, authHeader)
      .then(e => {
        if (e) {
          return { data: { user: { name: 'Admin', tier: 'Premium', avatar: '' } } }
        }
      })
      .catch(e => {
        return null
      })
    console.log(response)
    if (!response) {
      setSession(null)
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      })
      return
    }
    const { user } = response.data

    // console.log(response)

    setSession('str')
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    })

    if(user) {
      rootDispatch(getCurrentBrand())
    }
  }

  const logout = () => {
    setSession(null)
    dispatch({ type: 'LOGOUT' })
  }

  const register = async (email, name, password) => {
    const response = await axios.post('/api/account/register', {
      email,
      name,
      password
    })
    const { accessToken, user } = response.data

    window.localStorage.setItem('accessToken', accessToken)

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    })
  }

  useEffect(() => {
    // checking if user has not forbidden token
    initialiseUser()
  }, [])

  const initialiseUser = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken')
      // fetching users profile
      const usersProfile = await fetchSelfProfile()
      console.log(usersProfile, 'usersProfile')
      if (!usersProfile) {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
        return
      }
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: true,
          user: usersProfile
        }
      })

      rootDispatch(getCurrentBrand())

    } catch (e) {
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      })
      console.log(`User is not authorized: ${e}`)
    }
  }

  if (!state.isInitialised) {
    return <SplashScreen />
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
