import { useCallback, useEffect, useMemo } from 'react'
import { useRestApi } from './useRestApi'

export default function useBrandAppPreferences(venueSlug) {
  const [getPreferencesState, getPreferencesApi] = useRestApi(`${venueSlug ? `/${venueSlug}` : ''}/appPreferences`)
  const [updatePreferencesState, updatePreferencesApi] = useRestApi(
    `${venueSlug ? `/${venueSlug}` : ''}/appPreferences`
  )

  const update = useCallback(
    data => {
      updatePreferencesApi.sendRequest({
        method: 'PATCH',
        data
      })
    },
    [getPreferencesState.data, updatePreferencesApi.sendRequest]
  )

  const addDishesToPopular = useCallback(
    dishesIds => {
      const updatedIds = [...(getPreferencesState.data?.preferences?.popularDishesIds || []), ...dishesIds]

      updatePreferencesApi
        .sendRequest({
          method: 'PATCH',
          data: {
            popularDishesIds: updatedIds
          }
        })
        .then(data => {
          getPreferencesApi.setData({
            ...getPreferencesState.data,
            preferences: data
          })
        })
    },
    [getPreferencesState.data, updatePreferencesApi.sendRequest]
  )

  const updatePopularDishes = useCallback(
    dishesIds => {
      updatePreferencesApi
        .sendRequest({
          method: 'PATCH',
          data: {
            popularDishesIds: dishesIds
          }
        })
        .then(data => {
          getPreferencesApi.setData({
            ...getPreferencesState.data,
            preferences: data
          })
        })
    },
    [getPreferencesState.data, updatePreferencesApi.sendRequest]
  )

  const removeDishesFromPopular = useCallback(
    dishesIds => {
      const updatedIds = getPreferencesState.data?.preferences?.popularDishesIds?.filter(id => !dishesIds.includes(id))

      updatePreferencesApi
        .sendRequest({
          method: 'PATCH',
          data: {
            popularDishesIds: updatedIds
          }
        })
        .then(data => {
          getPreferencesApi.setData({
            ...getPreferencesState.data,
            preferences: data
          })
        })
    },
    [getPreferencesState.data, updatePreferencesApi.sendRequest]
  )

  useEffect(() => {
    getPreferencesApi.sendRequest()
  }, [getPreferencesApi.sendRequest])

  const api = useMemo(
    () => ({
      update,
      addDishesToPopular,
      updatePopularDishes,
      removeDishesFromPopular
    }),
    [update, addDishesToPopular, removeDishesFromPopular, updatePopularDishes]
  )

  const state = useMemo(
    () => ({
      loading: getPreferencesState.loading,
      appPreferences: getPreferencesState.data?.preferences,
      error: getPreferencesState.error,
      updatingState: updatePreferencesState
    }),
    [getPreferencesState, updatePreferencesState]
  )

  return [state, api]
}
