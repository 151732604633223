import { config } from 'src/constants'

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
}

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
}

export const authHeader = {
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

export const rolesTitles = {
  user: 'Посетитель',
  ex_icon: 'Посетитель',
  office: 'Сотрудник офиса',
  management: 'Менеджмент',
  owner: 'Владелец',
  manager: 'Менеджер ресторана',
  cashier: 'Кассир',
  runner: 'Раннер / Упаковщик',
  waiter: 'Официант',
  bar: 'Бариста',
  kitchen: 'Повар',
  admin: 'Администратор',
  candidate: 'Кандидат'
}

export const sessionsEndpointUrl = `${config.API_URL3}/backoffice/${window.busytable.brandSlug}/sessions`
export const themesEndpointUrl = `${config.API_URL3}/backoffice/${window.busytable.brandSlug}/themes`
export const remoteSessionUrl = `${config.ORIGIN_URL}/auth/self`
export const translationsEndpoint = `${config.API_URL3}/backoffice/${window.busytable.brandSlug}/translations`
