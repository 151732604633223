import {
  ADD_TAG_ERROR,
  ADD_TAG_STARTED,
  ADD_TAG_SUCCESS,
  DELETE_TAG_ERROR,
  DELETE_TAG_STARTED,
  DELETE_TAG_SUCCESS,
  GET_TAGS_ERROR,
  GET_TAGS_STARTED,
  GET_TAGS_SUCCESS,
  RESET_RESULT,
  UPDATE_TAG_ERROR,
  UPDATE_TAG_STARTED,
  UPDATE_TAG_SUCCESS
} from './tags.types'

export const getTagsSuccess = tags => ({
  type: GET_TAGS_SUCCESS,
  tags
})

export const getTagsStarted = () => ({
  type: GET_TAGS_STARTED
})

export const getTagsError = reason => ({
  type: GET_TAGS_ERROR,
  reason
})

export const updateTagSuccess = updatedTagObj => ({
  type: UPDATE_TAG_SUCCESS,
  tag: updatedTagObj
})

export const updateTagStarted = id => ({
  type: UPDATE_TAG_STARTED,
  id
})

export const updateTagError = reason => ({
  type: UPDATE_TAG_ERROR,
  reason
})

export const deleteTagSuccess = id => ({
  type: DELETE_TAG_SUCCESS,
  id
})

export const deleteTagStarted = id => ({
  type: DELETE_TAG_STARTED,
  id
})

export const deleteTagError = reason => ({
  type: DELETE_TAG_ERROR,
  reason
})

export const createTagSuccess = tag => ({
  type: ADD_TAG_SUCCESS,
  tag
})

export const createTagStarted = tag => ({
  type: ADD_TAG_STARTED,
  tag
})

export const createTagError = reason => ({
  type: ADD_TAG_ERROR,
  reason
})

export const resetTagsResult = () => ({
  type: RESET_RESULT
})
