import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
  languages: [],
  loading: true
}

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLanguages(state, action) {
      state.languages = action.payload
      state.loading = false
    }
  }
})

export const reducer = slice.reducer

export const selectSettingsSlice = state => state.settings
export const selectLanguages = createSelector(selectSettingsSlice, x => x.languages)
export const selectLanguagesLoading = createSelector(selectSettingsSlice, x => x.loading)
export const selectLanguagesEntities = createSelector(selectSettingsSlice, x =>
  x.languages.reduce((acc, curr) => ({ ...acc, [curr]: '' }), {})
)

export const { setLanguages } = slice.actions

export default slice
