import { languages } from 'src/globals/enumerations'

export const findDefaultLanguage = () => {
  const appStorage = window.localStorage
  const alreadySelectedLang = appStorage.getItem('lang')
  if (alreadySelectedLang) {
    return alreadySelectedLang
  }
  const usersLanugage = navigator.language || navigator.userLanguage
  if (usersLanugage.includes('-')) {
    const [language] = usersLanugage.split('-')
    if (languages[language]) {
      return language
    }
  }
  /**
   * @todo // change in to the fallback lanugage if there is any
   */
  return 'uk'
}
