const translationsGlobal = {
  ru: {
    network: 'Сеть',
    overview: 'Обзор',
    venues: 'Рестораны',
    employees: 'Сотрудники',
    programsOfLoyalty: 'Программы лояльности',
    promotions: 'Акции',
    identity: 'Айдентика',
    reports: 'Отчеты',
    venue: 'Ресторан',
    menu: 'Меню',
    dishes: 'Блюда',
    extraParameters: 'Доп. параметры',
    modifiers: 'Модификаторы',
    lang: 'Языки',
    copyright: 'Копирайтинг',
    cookingTime: 'Время приготовления',
    integrations: 'Интеграции',
    qrGenerator: 'QR генератор',
    guestApp: 'Приложение гостя',
    appearance: 'Внешний вид',
    customPages: 'Кастомные страницы',
    modules: 'Модули',
    adminPanel: 'Панель администратора',
    chooseBrand: 'Выбор бренда',
    chooseLangForApp: 'Выберите языки, которые должны быть доступны гостям в приложении.',
    russian: 'Русский',
    ukrainian: 'Украинский',
    english: 'Английский',
    dragPicture: 'Перетащите картинку сюда или',
    choose: 'выберите',
    fileManual: 'файл в ручную',
    requests: 'Запросы',
    chooseReport: 'Выберите отчет',
    report: 'Отчет',
    generalReport: 'Общий Отчет',
    parameter: 'Параметр',
    strings: 'Строк',
    columns: 'Столбцов',
    parameterOfRequest: 'Параметры запроса',
    openInNewWindow: 'Открыть в новом окне',
    run: 'Запустить',
    menuPlaceholderFirstPart: 'Тут пока нет Меню / Категорий / Блюд',
    menuPlaceholderSecondPart: 'Для того чтоб добавить блюда / категории',
    menuPlaceholderThirdPart: 'нажмите кнопку Добавить / Создать',
    create: 'Создать',
    delete: 'Удалить',
    addLanguage: 'Добавить другой язык',
    language: 'Язык',
    add: 'Добавить',
    selectLocaleToAdd: 'Выберите язык',
    apiError: 'Произошла ошибка, попробуйте снова'
  },
  uk: {
    network: 'Мережа',
    overview: 'Огляд',
    venues: 'Ресторани',
    employees: 'Співробітники',
    programsOfLoyalty: 'Програми лояльності',
    promotions: 'Акції',
    identity: 'Айдентика',
    reports: 'Звіти',
    venue: 'Ресторан',
    menu: 'Меню',
    dishes: 'Страви',
    extraParameters: 'Доп. параметри',
    modifiers: 'Модифікатори',
    lang: 'Мови',
    copyright: 'Копірайтинг',
    cookingTime: 'Час приготування',
    integrations: 'Інтеграції',
    qrGenerator: 'QR генератор',
    guestApp: 'Додаток відвідувача',
    appearance: 'Зовнішній вигляд',
    customPages: 'Кастомні сторінки',
    modules: 'Модулі',
    adminPanel: 'Панель админістратора',
    chooseBrand: 'вибір бренду',
    chooseLangForApp: 'Оберіть мови, які мають бути доступними у додатку.',
    russian: 'Російська',
    ukrainian: 'Українська',
    english: 'Англійська',
    dragPicture: 'Перетягніть картинку сюди або',
    choose: 'оберіть',
    fileManual: 'файл в ручну',
    requests: 'Запити',
    chooseReport: 'Оберіть звіт',
    report: 'Звіт',
    generalReport: 'Загальний Звіт',
    parameter: 'Параметр',
    strings: 'Строк',
    columns: 'Стовбцов',
    parameterOfRequest: 'Параметри запиту',
    openInNewWindow: 'Відкрити у нової вкладці',
    run: 'Запустити',
    menuPlaceholderFirstPart: 'Тут поки немає Меню / Категорій / Страв',
    menuPlaceholderSecondPart: 'Для того щоб додати страви / категорії',
    menuPlaceholderThirdPart: 'натисніть кнопку Додати / Створити',
    create: 'Створити',
    delete: 'Видалити',
    addLanguage: 'Додати іншу мову',
    language: 'Мова',
    add: 'Додати',
    selectLocaleToAdd: 'Оберіть мову',
    apiError: 'Виникла помилка, спробуйте знову'
  }
}
export default translationsGlobal
