import { createSelector, createSlice } from '@reduxjs/toolkit'
import objFromArray from 'src/utils/objFromArray'
import axios from 'src/utils/axios'
import { config } from 'src/constants'

const initialState = {
  entities: {},
  allIds: [],
  updated: []
}

const slice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setData(state, action) {
      const { data } = action.payload
      const sortedIds = data
        .sort((a, b) => {
          const aString = a.external.hierarchy + a.external.name
          const bString = b.external.hierarchy + b.external.name
          return aString.localeCompare(bString)
        })
        .map(x => x.id)

      state.entities = objFromArray(data)
      state.allIds = sortedIds
    },
    updateExt(state, action) {
      const { id, dishId, optionIds } = action.payload
      state.entities[id].dishId = dishId
      state.entities[id].mapped = true
      state.entities[id].optIds = optionIds

      state.updated.push(id)
    },
    updateExtPatch(state, action) {
      const { entities, ids } = action.payload
      state.entities = { ...state.entities, ...entities }
      state.updated = [...state.updated, ...ids]
    },
    clearUpdated(state, _) {
      state.updated = []
    }
  }
})

export const reducer = slice.reducer

const authHeader = {
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

export const fetchIntegrationExt =
  (newBrand = null) =>
  async dispatch => {
    const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/external`
    const response = await axios.get(url, authHeader)

    dispatch(slice.actions.setData(response.data))
  }

export const saveIntegrationTable =
  (data, newBrand = null) =>
  async dispatch => {
    const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/external`
    const body = data
    const response = await axios.post(url, body, authHeader)
    if (response.status === 200) {
      dispatch(slice.actions.clearUpdated())
    }

    return response
  }

export const setLinkToDish = (id, dishId, optionIds) => async dispatch => {
  dispatch(slice.actions.updateExt({ id, dishId, optionIds }))
}

export const setLinkToDishPatch = (entities, ids) => async dispatch => {
  dispatch(slice.actions.updateExtPatch({ entities, ids }))
}

export const selectExt = state => state.integrations

/**
 * из данных создает объект с ключами venueId
 */
const splitToVenues = ({ entities, allIds }) => {
  return allIds.reduce((acc, val) => {
    const { venueId } = entities[val]
    acc[venueId] = acc[venueId] ? [...acc[venueId], val] : [val]
    return acc
  }, {})
}

export const selectExtByVenue = createSelector(selectExt, splitToVenues)

export const selectUpdatedObj = createSelector(selectExt, ext => {
  const result = ext.updated.reduce((acc, val) => {
    acc[val] = ext.entities[val]
    return acc
  }, {})
  return result
})

export const selectStatsByVenue = createSelector(selectExtByVenue, ext => {
  return []
  // const result = Object.entries(ext).reduce((acc, [venueId, val]) => {
  //   const itemsArr = Object.values(val.entities)
  //   const linked = itemsArr.filter(x => x.linked && (x.linked.dishId || x.linked.optionIds.length))
  //   acc[venueId] = { total: itemsArr.length, linked: linked.length }
  //   return acc
  // }, {})

  // return result
})

export default slice
