export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS'
export const GET_TAGS_STARTED = 'GET_TAGS_STARTED'
export const GET_TAGS_ERROR = 'GET_TAGS_ERROR'
export const UPDATE_TAG_STARTED = 'UPDATE_TAG_STARTED'
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS'
export const UPDATE_TAG_ERROR = 'UPDATE_TAG_ERROR'
export const ADD_TAG_STARTED = 'ADD_TAG_STARTED'
export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS'
export const ADD_TAG_ERROR = 'ADD_TAG_ERROR'
export const DELETE_TAG_STARTED = 'DELETE_TAG_STARTED'
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS'
export const DELETE_TAG_ERROR = 'DELETE_TAG_ERROR'
export const RESET_RESULT = 'RESET_RESULT'
