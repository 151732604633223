import { createSelector, createSlice } from '@reduxjs/toolkit'
// import axios from 'axios'
import { config } from 'src/constants'
import axios from 'src/utils/axios'
import { idsFromEntities } from 'src/utils/idsFromEntities'
import objFromArray from 'src/utils/objFromArray'

const initialState = {
  entities: {},
  ids: [],
  loading: true
}

const slice = createSlice({
  name: 'venues',
  initialState,
  reducers: {
    setVenues(state, action) {
      state.entities = objFromArray(action.payload)
      state.ids = idsFromEntities({ entities: state.entities })
      state.loading = false
    }
  }
})

const entitiesToArr = state => {
  return state.ids.map(id => state.entities[id])
}

export const reducer = slice.reducer

const authHeader = {
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

export const fetchVenues = (newBrand = null) => async dispatch => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/venues`
  const response = await axios.get(url, authHeader)

  dispatch(slice.actions.setVenues(response.data))
}

export const postVenueApi = async (venue, newBrand = null) => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/venues`
  const body = venue
  const response = await axios
    .post(url, body, authHeader)
    .then(e => e)
    .catch(err => ({ status: 'fail' }))
  return response
}

export const patchVenueApi = async (venue, newBrand = null) => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/venues/${venue.id}`
  const body = venue
  const response = await axios
    .patch(url, body, authHeader)
    .then(e => e)
    .catch(err => ({ status: 'fail' }))
  return response
}

export const selectVenueSlices = state => state.venues

export const selectVenueLoading = createSelector(selectVenueSlices, x => x.loading)

export const selectAllVenues = createSelector(selectVenueSlices, entitiesToArr)
export const selectVenueEntities = createSelector(selectVenueSlices, x => x.entities)
export const selectVenueById = id => createSelector(selectVenueEntities, x => x[id])

export default slice
