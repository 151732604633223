import React, { Suspense, Fragment, lazy } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import DashboardLayout from 'src/layouts/DashboardLayout'
import MainLayout from 'src/layouts/MainLayout'
import LoadingScreen from 'src/components/LoadingScreen'
import AuthGuard from 'src/components/AuthGuard'
import GuestGuard from 'src/components/GuestGuard'
import DishListView from 'src/views/dishes/DishListView'

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        )
      })}
    </Switch>
  </Suspense>
)

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/integration',
        component: lazy(() => import('src/views/integration'))
      },
      {
        exact: true,
        path: '/app/multimenu',
        component: lazy(() => import('src/views/multimenu/MultimenuListView'))
      },
      {
        exact: true,
        path: '/app/multimenu/:menuId',
        component: lazy(() => import('src/views/multimenu/MultimenuDetailsView'))
      },
      {
        exact: true,
        path: '/app/multimenu_create/',
        component: lazy(() => import('src/views/multimenu/MultimenuDetailsView'))
      },
      {
        exact: true,
        path: '/app/multimenu/:menuId/edit/:catId',
        component: lazy(() => import('src/views/multimenu/MultimenuEditView'))
      },
      {
        exact: true,
        path: '/app/multimenu/:menuId/create_category',
        component: lazy(() => import('src/views/multimenu/MultimenuEditView'))
      },
      {
        exact: true,
        path: '/app/categories_create',
        component: lazy(() => import('src/views/categories/CategoriesCreateView'))
      },
      {
        exact: true,
        path: '/app/categories/:catId/edit',
        component: lazy(() => import('src/views/categories/CategoriesEditView'))
      },
      // {
      //   exact: true,
      //   path: '/app/timeCalculator',
      //   component: lazy(() => import('src/views/shared/CookingTimeCalculator'))
      // },
      {
        exact: true,
        path: '/app/brands/ui',
        component: lazy(() => import('src/views/brandSelector'))
      },
      {
        exact: true,
        path: '/app/dishes',
        component: DishListView
      },
      {
        exact: true,
        path: '/app/dishes_create',
        component: lazy(() => import('src/views/dishes/DishView'))
      },
      {
        exact: true,
        path: '/app/dishes/:dishId',
        component: lazy(() => import('src/views/dishes/DishView'))
      },
      {
        exact: true,
        path: '/app/optionsets',
        component: lazy(() => import('src/views/optionsets/OptionsetListView'))
      },
      {
        exact: true,
        path: '/app/optionsets_create',
        component: lazy(() => import('src/views/optionsets/OptionsetCreateView'))
      },
      {
        exact: true,
        path: '/app/optionsets/:optionsetId/edit',
        component: lazy(() => import('src/views/optionsets/OptionsetEditView'))
      },
      {
        exact: true,
        path: '/app/modifiers',
        component: lazy(() => import('src/views/modifiers/ModifierListView'))
      },
      {
        exact: true,
        path: '/app/modifiers/:modId/edit',
        component: lazy(() => import('src/views/modifiers/ModifierEditView'))
      },
      {
        exact: true,
        path: '/app/modifiers_create',
        component: lazy(() => import('src/views/modifiers/ModifierCreateView'))
      },
      {
        exact: true,
        path: '/app/staff',
        component: lazy(() => import('src/views/users/UserListView'))
      },
      {
        exact: true,
        path: '/app/staff/:userId',
        component: lazy(() => import('src/views/users/UserView'))
      },
      {
        exact: true,
        path: '/app/venues',
        component: lazy(() => import('src/views/venues/VenueListView'))
      },
      {
        exact: true,
        path: '/app/venues_create',
        component: lazy(() => import('src/views/venues/VenueView'))
      },
      {
        exact: true,
        path: '/app/venues/:venueId',
        component: lazy(() => import('src/views/venues/VenueView'))
      },
      {
        exact: true,
        path: '/app/venues/:venueId/edit',
        component: lazy(() => import('src/views/venues/VenueView'))
      },
      {
        exact: true,
        path: '/app/analytics',
        component: lazy(() => import('src/views/reports/AnalyticsView'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: lazy(() => import('src/views/reports/ReportsView'))
      },
      {
        exact: true,
        path: '/app/copyright',
        component: lazy(() => import('src/views/copyright/CopyrightListView'))
      },
      {
        exact: true,
        path: '/app/identity',
        component: lazy(() => import('src/views/identity/IdentitySettingsView'))
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/brandSettings',
        component: lazy(() => import('src/views/languages'))
      },
      {
        exact: true,
        path: '/app/appSettings',
        component: lazy(() => import('src/views/settings/AppSettingsView'))
      },
      {
        exact: false,
        path: '/app/client/ui',
        component: lazy(() => import('src/views/clientui/index'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to='/app/analytics' />
      },
      {
        component: () => <Redirect to='/404' />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to='/app' />
      },
      {
        component: () => <Redirect to='/404' />
      }
    ]
  }
]

export default routes
