import { AppBar, Box, Hidden, IconButton, makeStyles, SvgIcon, Toolbar, Select, MenuItem } from '@material-ui/core'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Menu as MenuIcon } from 'react-feather'
import { Link as RouterLink } from 'react-router-dom'
import Logo from 'src/components/Logo'
import { THEMES } from 'src/constants'
import { setSelectedLanguage } from 'src/store/general/actions'
import { selectBrandOverload } from 'src/slices/brands'
import { useSelector } from 'src/store'
import Account from './Account'
import { getSelectedLanguage } from 'src/store/general/selectors'
import { languages } from 'src/globals/enumerations'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: window.mode && window.mode === 'develop' ? '#ffce00' : theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: window.mode && window.mode === 'develop' ? '#ffce00' : theme.palette.primary.main
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  },
  langselect: {
    textTransform: 'uppercase',
    fontSize: '16px',
    fontWeight: 300,
    padding: '8px',
    borderRadius: '4px',
    background: 'inherit',
    border: 'none',
    color: '#ffffff',
    '&:focus-visible': {
      outline: 'none'
    },
    '& option': {
      color: '#263238'
    }
  }
}))

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const overloadedBrand = useSelector(selectBrandOverload)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const onLanguageChange = e => {
    dispatch(setSelectedLanguage(e.target.value))
    const appStorage = window.localStorage
    window.localStorage.setItem('lang', e.target.value)
  }
  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color='inherit' onClick={onMobileNavOpen}>
            <SvgIcon fontSize='small'>
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to='/'>
            <Logo />
            {overloadedBrand}
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        {/* <Search />
        <Contacts />
        <Notifications />
        <Settings /> */}
        <div>
          <select className={classes.langselect} onChange={onLanguageChange} onClick={e => e.stopPropagation()}>
            {Object.keys(languages).map(lang => {
              return <option key={lang} selected={lang === selectedLanguage}>{`${lang}`}</option>
            })}
          </select>
        </div>
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
}

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
}

export default TopBar
