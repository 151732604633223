import {
  GET_CURRENT_BRAND_STARTED,
  GET_CURRENT_BRAND_SUCCESS,
  GET_CURRENT_BRAND_ERROR,
  UPDATE_CURRENT_BRAND,
  UPDATE_CURRENT_BRAND_SUCCESS
} from './types'

const initialState = {
  brand: null,
  errorMessage: null,
  isLoading: false,
  isUpdating: false
}

const currentBrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENT_BRAND_SUCCESS: {
      return {
        ...state,
        brand: action.brand,
        isLoading: false
      }
    }

    case GET_CURRENT_BRAND_ERROR: {
      return {
        ...state,
        errorMessage: action.reason,
        isLoading: false
      }
    }

    case GET_CURRENT_BRAND_STARTED: {
      return {
        ...state,
        isLoading: true
      }
    }

    case UPDATE_CURRENT_BRAND: {
      return {
        ...state,
        isUpdating: true,
        brand: {
          ...state.brand,
          ...action.updateBrandObj
        }
      }
    }

    case UPDATE_CURRENT_BRAND_SUCCESS: {
      return {
        ...state,
        isUpdating: false
      }
    }

    default:
      return state
  }
}

export default currentBrandReducer
