export const GET_DISHES_SUCCESS = 'GET_DISHES_SUCCESS'
export const GET_DISHES_STARTED = 'GET_DISHES_STARTED'
export const GET_DISHES_ERROR = 'GET_DISHES_ERROR'
export const UPDATE_DISH_STARTED = 'UPDATE_DISH_STARTED'
export const UPDATE_DISH_SUCCESS = 'UPDATE_DISH_SUCCESS'
export const UPDATE_DISH_ERROR = 'UPDATE_DISH_ERROR'
export const ADD_DISH_STARTED = 'ADD_DISH_STARTED'
export const ADD_DISH_SUCCESS = 'ADD_DISH_SUCCESS'
export const ADD_DISH_ERROR = 'ADD_DISH_ERROR'
export const DELETE_DISH_STARTED = 'DELETE_DISH_STARTED'
export const DELETE_DISH_SUCCESS = 'DELETE_DISH_SUCCESS'
export const DELETE_DISH_ERROR = 'DELETE_DISH_ERROR'
