import { createSlice } from '@reduxjs/toolkit'
import axios from 'src/utils/axios'
import { config } from 'src/constants'

const translation = {
  about: {
    en: 'About us',
    uk: 'Про сервіс',
    ru: 'О сервисе'
  },
  aboutApplication: {
    en: 'About Application',
    uk: 'Про додаток',
    ru: 'Про приложение'
  },
  contactUs: {
    en: 'Contact us',
    uk: "Зв'язатися з нами",
    ru: 'Связаться с нами'
  },
  orderHistory: {
    en: 'Order history',
    uk: 'Історія замовлень',
    ru: 'История заказов'
  },
  venueSelect: {
    en: 'Choose a restaurant',
    uk: 'Вибір закладу',
    ru: 'Выбор заведения'
  },
  inputTable: {
    en: 'Input table number',
    uk: 'Ввести номерок',
    ru: 'Ввести номерок'
  },
  profile: {
    en: 'Profile',
    uk: 'Профіль',
    ru: 'Профиль'
  },
  numDishesWaiting: {
    en: '{{count|dish|dishes}} waiting for payment',
    uk: '{{count|страва очікує|страви очікують|страв очікують}} оплати',
    ru: '{{count|блюдо ожидает|блюда ожидают|блюд ожидают}} оплату'
  },
  numDishesCooking: {
    en: '{{count|dish is|dishes are}} cooking',
    uk: '{{count|страва|страви|страв}} готується',
    ru: '{{count|блюдо|блюда|блюд}} готовится'
  },
  numDishesReady: {
    en: '{{count|dish is|dishes are}} ready',
    uk: '{{count|страва|страви|страв}} готово',
    ru: '{{count|блюдо|блюда|блюд}} готово'
  },
  'orderCard.dishesCount': {
    en: 'and also {{count|dish|dishes}}...',
    uk: 'і ще {{count|страва|страви|страв}}...',
    ru: 'и еще {{count|блюдо|блюда|блюд}}...'
  },
  pressToScan: {
    en: 'Click to scan',
    uk: 'Натисніть щоб відсканувати',
    ru: 'Нажмите что бы отсканировать'
  },
  tableless: {
    en: 'WITHOUT A TABLE',
    uk: 'БЕЗ СТОЛА',
    ru: 'БЕЗ СТОЛА'
  },
  yourTable: {
    en: 'Your table:',
    uk: 'Ваш столик:',
    ru: 'Ваш столик:'
  },
  settings: {
    en: 'Settings',
    uk: 'Налаштування',
    ru: 'Настройки'
  },

  termsOfUse: {
    en: 'Terms of Use',
    uk: 'Умови Використання',
    ru: 'Условия Использования'
  },
  privacyPolicy: {
    en: 'Privacy Policy',
    uk: 'Політика Конфіденційності',
    ru: 'Политика Конфиденциальности'
  },
  aboutText: {
    en: 'We all come to work every day to enable you with a full and rewarding coffeehouse experience.',
    uk:
      'Ми подумали - нікому не подобається стояти в черзі, давайте зробимо такий додаток, щоб можна було замовити що завгодно прямо за столик. І щоб його не потрібно було шукати в аппсторе і завантажувати, щоб воно запускалося відразу зі столу. І щоб не вимагало реєстрації. І щоб можна було зберегти карту, і платити в 1 клік. І щоб можна було додати улюблений круасан і латте в обране, і витрачати на замовлення не більше 10 секунд. І щоб....\n\n[Пишіть нам](tg://resolve?domain=khlbn_support), ми будемо раді почути, що ми можемо поліпшити або додати! Ми хочемо, щоб у нас були найсмачніші круасани, і найкраще додаток.',
    ru:
      'Мы подумали - никому не нравится стоять в очереди, давайте сделаем такое приложение, чтобы можно было заказать что угодно прямо за столик. И чтобы его не нужно было искать в аппсторе и скачивать, чтобы оно запускалось сразу со стола. И чтобы не требовало регистрации. И чтобы можно было сохранить карту, и платить в 1 клик. И чтобы можно было добавить любимый круассан и латте в избранное, и тратить на заказ не больше 10 секунд. И чтобы....\n\n[Пишите нам](tg://resolve?domain=khlbn_support), мы будем рады услышать, что мы можем улучшить или добавить! Мы хотим, чтобы у нас были самые вкусные круассаны, и самое лучшее приложение.'
  },
  aboutTextLink: {
    en: '\n\n[Write to us](tg://resolve?domain=khlbn_support)',
    uk: '\n\n[Пишіть нам](tg://resolve?domain=khlbn_support)',
    ru: '\n\n[Пишите нам](tg://resolve?domain=khlbn_support)'
  },
  'map.disabledLabel': {
    en: 'Application not avaible at the moment',
    uk: 'Додаток тимчасово не доступний',
    ru: 'Приложение временно недоступно'
  },
  'map.chooseBtn': {
    en: 'Select',
    uk: 'Выбрати',
    ru: 'Выбрать'
  },
  'map.openBtn': {
    en: 'Open',
    uk: 'Відкрити',
    ru: 'Открыть'
  },
  'tableNumber.notSelectedVenue': {
    en: 'venue not selected',
    uk: 'заклад не обрано',
    ru: 'заведение не выбрано'
  },
  'tableNumber.disabledApp': {
    en: 'Application disabled',
    uk: 'Додаток відключено',
    ru: 'Приложение отключено'
  },
  'tableNumber.networkError': {
    en: 'Network error!',
    uk: 'Помилка мережі!',
    ru: 'Ошибка сети!'
  },
  'tableNumber.selectTableNumber': {
    en: 'Enter sticker number',
    uk: 'Введіть номер наклейки',
    ru: 'Введите номер наклейки'
  },
  'general.continue': {
    en: 'Continue',
    uk: 'Продовжити',
    ru: 'Продолжить'
  },
  'general.today': {
    en: 'Today',
    uk: 'Сьогодні',
    ru: 'Сегодня'
  },
  'general.date': {
    en: 'Date',
    uk: 'Дата',
    ru: 'Дата'
  },
  'listSection.optionNotFound': {
    en: 'Option not found',
    uk: 'Опція не знайдена',
    ru: 'Опция не найдена'
  },
  'listSection.dishNotFound': {
    en: 'Dish not found',
    uk: 'Страва не знайдена',
    ru: 'Блюдо не найдено'
  },
  'general.delete': {
    en: 'Delete',
    uk: 'Видалити',
    ru: 'Удалить'
  },
  'listSection.deleteFavorite': {
    en: 'REMOVE FROM FAVORITES',
    uk: 'ВИДАЛИТИ З ОБРАНОГО',
    ru: 'УБРАТЬ ИЗ ИЗБРАННОГО'
  },
  'general.addToFavorite': {
    en: 'Add to favourites',
    uk: 'Додати в обране',
    ru: 'Добавить в избранное'
  },
  'general.favoriteLabel': {
    en: 'Favorites',
    uk: 'Обране',
    ru: 'Избранное'
  },
  'listSection.duplicate': {
    en: 'Duplicate',
    uk: 'Дублювати',
    ru: 'Дублировать'
  },
  'listSection.povtorit': {
    en: 'Повторить',
    uk: 'Повторить',
    ru: 'Повторить'
  },
  'listSection.open': {
    en: 'Open',
    uk: 'Відкрити',
    ru: 'Открыть'
  },
  'orderSection.taxi': {
    en: 'Taxi',
    uk: 'Таксі',
    ru: 'Такси'
  },
  'orderSection.messageSent': {
    en: 'You message have been sent',
    uk: 'Повідомлення менеджеру відправлено',
    ru: 'Сообщение менеджеру отправлено'
  },
  'orderSection.messageInfo': {
    en: 'Please, enter your phone number in profile.',
    uk: 'Вкажіть свій номер телефону в профілі для зміни замовлення',
    ru: 'Укажите свой номер телефона в профиле для изменения заказа'
  },
  'orderSection.messageButton': {
    en: 'Click here and our manager will call you',
    uk: "Натисніть сюди - і з вами зв'яжеться менеджер",
    ru: 'Нажмите сюда — и с вами свяжется менеджер'
  },
  'orderSection.notificationInfo': {
    en: "I'm already arrived and ready to pick up my order",
    uk: 'Я на місці, готовий (a) забрати замовлення',
    ru: 'Я на месте, готов(a) забрать заказ'
  },
  'orderSection.notificationSent': {
    en: 'Message sent',
    uk: 'Повідомлення відправлено',
    ru: 'Уведомление отправлено'
  },
  'orderSection.timeleft': {
    en: 'Left',
    uk: 'Залишилося',
    ru: 'Осталось'
  },
  'orderSection.timeleftH': {
    en: 'h',
    uk: 'г',
    ru: 'ч'
  },
  'orderSection.timeleftM': {
    en: 'm',
    uk: 'хв',
    ru: 'м'
  },
  'orderSection.timeleftD': {
    en: 'days',
    uk: 'днiв',
    ru: 'дня'
  },
  'orderSection.dishStoplist': {
    en: 'Some dishes are not available',
    uk: 'Деякі страви закінчилися',
    ru: 'Некоторые блюда закончились'
  },
  'orderSection.zone': {
    en: 'Takeout zone',
    uk: 'Зона выдачі',
    ru: 'Зона выдачи'
  },
  'orderSection.of': {
    en: 'of',
    uk: 'з',
    ru: 'из'
  },
  'paymentSection.agreed': {
    en: 'I agree with terms of use',
    uk: 'Все вірно, з умовами згоден',
    ru: 'Все верно, с условиями согласен'
  },
  'paymentSection.commentPlaceholder': {
    en: 'Write here if you want to add some order information🙏🏻',
    uk: 'Напишіть сюди, якщо бажаєте додати якусь інформацію про замовлення🙏🏻',
    ru: 'Напишите сюда, если хотите добавить какую-то информацию о заказе🙏🏻'
  },
  'paymentSection.later': {
    en: 'Enter later',
    uk: 'Зазначу пізніше',
    ru: 'Укажу позже'
  },
  'paymentSection.phonePlaceholder': {
    en: 'Enter your phone',
    uk: 'Вкажіть телефон',
    ru: 'Указать телефон'
  },
  'paymentSection.phoneLabel': {
    en: 'Phone',
    uk: 'Телефон',
    ru: 'Телефон'
  },
  'paymentSection.namePlaceholder': {
    en: 'Enter your name',
    uk: "Вкажіть ім'я",
    ru: 'Указать имя'
  },
  'paymentSection.nameLabel': {
    en: 'Name',
    uk: "Ім'я",
    ru: 'Имя'
  },
  'paymentSection.addressPlaceholder': {
    en: 'Enter your address',
    uk: 'Вказати адресу',
    ru: 'Указать адрес'
  },
  'general.adress': {
    en: 'Address',
    uk: 'Адреса',
    ru: 'Адрес'
  },
  'paymentSection.addressFrom': {
    en: 'Where',
    uk: 'Де',
    ru: 'Где'
  },
  'paymentSection.addressTo': {
    en: 'From',
    uk: 'Звідки',
    ru: 'Откуда'
  },
  'branding.paymentSectionDiv5Text': {
    en: 'and we will bring out your order outside.',
    uk: 'і ваше замовлення винесуть на вулицю або прямо до машини.',
    ru: 'и ваш заказ вынесут на улицу или прямо к машине.'
  },
  'branding.paymentSectionDiv5Strong': {
    en: "You can use button 'I'm arrived'",
    uk: 'Ви можете скористатися кнопкою «Я на місці»',
    ru: 'Вы можете воспользоваться кнопкой «Я на месте»'
  },
  'branding.paymentSectionDiv4Final': {
    en: '.',
    uk: '.',
    ru: '.'
  },
  'branding.paymentSectionDiv4Strong': {
    en: '',
    uk: 'просимо заходити всередину тільки в масці',
    ru: 'просим заходить внутрь только в маске'
  },
  'branding.paymentSectionDiv4Text': {
    en: '',
    uk: 'Ми дуже хочемо, щоб наші зустрічі були максимально безпечними, тому',
    ru: 'Мы очень хотим, чтобы наши встречи были максимально безопасными, поэтому'
  },
  'branding.paymentSectionDiv3Final': {
    en: '',
    uk: ' 😌.',
    ru: ' 😌.'
  },
  'branding.paymentSectionDiv3Strong': {
    en: 'Большой Васильковской 67/7',
    uk: 'Большой Васильковской 67/7',
    ru: 'Большой Васильковской 67/7'
  },
  'branding.paymentSectionDiv3Text': {
    en: 'You can pick up the order yourself at',
    uk: 'Ви можете забрати замовлення самі на',
    ru: 'Вы можете забрать заказ сами на'
  },
  'branding.paymentSectionDiv2Text': {
    en: 'Taxi fee was not included. You must pay it to taxi driver.',
    uk:
      "Таксі потрібно буде сплатити за фактом прибуття водія до вас, так як доставка не включена у вартість замовлення 🙏🏻 Водій не підніметься на ваш поверх - так що не забувайте, будь ласка, маску, виходячи за замовленням на подвiр'я❤️",
    ru:
      'Такси нужно будет оплатить по факту прибытия водителя к вам, так как доставка не включена в стоимость заказа 🙏🏻 Водитель не поднимется на ваш этаж — так что не забывайте, пожалуйста, маску, выходя за заказом на улицу❤️'
  },
  'branding.paymentSectionDiv1Text': {
    en: 'Please, provide your phone number and we will inform your about delivery fee and time.',
    uk: 'Залиште, будь ласка, свій номер телефону - ми напишемо про час доставки і вартості.',
    ru: 'Оставьте, пожалуйста, свой номер телефона — мы напишем о времени доставки и стоимости.'
  },
  'branding.paymentSectionDiv1Strong': {
    en: 'We will send your order by taxi to your address',
    uk: 'Ми відправимо замовлення до вас на таксі за вказаною адресою',
    ru: 'Мы отправим заказ к вам на такси по указанному адресу'
  },
  'general.pay': {
    en: 'Pay',
    uk: 'Сплатити',
    ru: 'Оплатить'
  },
  'general.chooseTable': {
    en: 'Choose a table',
    uk: 'Обрати столик',
    ru: 'Выбрать столик'
  },
  'paymentSection.chooseVenue': {
    en: 'Choose a restaurant',
    uk: 'Обрати ресторан',
    ru: 'Выбрать ресторан'
  },
  'segmentButton.here': {
    en: 'Inside',
    uk: 'У закладі',
    ru: 'Здесь'
  },
  'segmentButton.togo': {
    en: 'Takeout',
    uk: 'З собою',
    ru: 'С собой'
  },
  'segmentButton.delivery': {
    en: 'Delivery',
    uk: 'Доставка',
    ru: 'Доставка'
  },
  'shoppingCart.closedContent.notwork': {
    en: 'Non-working hours',
    uk: 'Неробочий час',
    ru: 'Нерабочее время'
  },
  'shoppingCart.closedContent.stoplisted': {
    en: 'Unavailable',
    uk: 'Немає в наявності',
    ru: 'Нет в наличии'
  },
  'general.myorder': {
    en: 'My order',
    uk: 'Моє замовлення',
    ru: 'Мой заказ'
  },
  'shoppingCart.closedContent.allpay': {
    en: 'Everything paid!',
    uk: 'Все сплачено!',
    ru: 'Все оплачено!'
  },
  'shoppingCart.closedContent.readytogo': {
    en: 'Ready to go',
    uk: 'Можна забрати',
    ru: 'Можно забрать'
  },
  'shoppingCart.contentOpen.placeholder1': {
    en: "You haven't ordered anything yet",
    uk: 'Ви ще нічого не замовили',
    ru: 'Вы еще ничего не заказали'
  },
  'shoppingCart.contentOpen.placeholder2': {
    en: '',
    uk: 'а ми, між іншим, тільки що пиріжків різних напекли',
    ru: 'а мы, между прочим, только что пирожков разных напекли'
  },
  'shoppingCart.notifyText': {
    en: 'Please, provide us with more information if needed 🙏🏻',
    uk: 'Напишите сюда, если нам нужно знать больше о том, как вы хотите забрать заказ 🙏🏻',
    ru: 'Напишите сюда, если нам нужно знать больше о том, как вы хотите забрать заказ 🙏🏻'
  },
  'shoppingCart.commentButton': {
    en: 'Send',
    uk: 'Надіслати',
    ru: 'Отправить'
  },
  'timeSelector.when': {
    en: 'When',
    uk: 'Коли',
    ru: 'Когда'
  },
  'timeSelector.selectPlaceholder': {
    en: 'Select time',
    uk: 'Обрати час',
    ru: 'Выбрать время'
  },
  'timeSelector.selectDefault': {
    en: 'On readiness',
    uk: 'По готовності',
    ru: 'По готовности'
  },
  'freeForm.phoneSelect': {
    en: 'Phone call',
    uk: 'Телефонний виклик',
    ru: 'Телефонный звонок'
  },
  'freeForm.save': {
    en: 'Save',
    uk: 'Зберегти',
    ru: 'Сохранить'
  },
  'freeForm.create': {
    en: 'Create',
    uk: 'Створити',
    ru: 'Создать'
  },
  'freeForm.namePlaceholder': {
    en: 'Enter your name',
    uk: "Введіть ім'я",
    ru: 'Введите имя'
  },
  'freeForm.lastnamePlaceholder': {
    en: 'Enter your last name',
    uk: 'Введіть прізвище',
    ru: 'Введите фамилию'
  },
  'freeForm.phonePlaceholder': {
    en: 'Phone',
    uk: 'Номер телефону',
    ru: 'Номер телефона'
  },
  'freeForm.emailPlaceholder': {
    en: 'Email',
    uk: 'Email адреса',
    ru: 'Email адрес'
  },
  'freeForm.passwordError': {
    en: 'Access code is wrong',
    uk: 'Код доступу невірний',
    ru: 'Код доступа неверный'
  },
  'freeForm.phoneError': {
    en: 'Wrong phone',
    uk: 'Невірний телефон',
    ru: 'Неправильный телефон'
  },
  'freeForm.emailError': {
    en: 'Wrong Email',
    uk: 'Невірний email',
    ru: 'Неправильный email'
  },
  'freeForm.passwordPlaceholder': {
    en: 'Access code',
    uk: 'Код доступу',
    ru: 'Код доступа'
  },
  'freeForm.contactPlaceholder': {
    en: 'preferred method of communication',
    uk: "Бажаний тип зв'язку",
    ru: 'Предпочитаемый тип связи'
  },
  'freeForm.unpaiderPlaceholder': {
    en: 'Unpaider type',
    uk: 'Категорія неплатника',
    ru: 'Категория неплательщика'
  },
  'general.popularLabel': {
    en: 'Popular',
    uk: 'Популярне',
    ru: 'Популярное'
  },
  'general.allDishes': {
    en: 'All dishes',
    uk: 'Все страви',
    ru: 'Все блюда'
  },
  'menuCard.limited': {
    en: 'limited',
    uk: 'лімітовано',
    ru: 'лимитировано'
  },
  'menuCard.preorder': {
    en: 'PREORDER',
    uk: 'ПЕРЕДЗАМОВЛЕННЯ',
    ru: 'ПРЕДЗАКАЗ'
  },
  'menuNavbar.allDishes': {
    en: 'Enter dish name',
    uk: 'Введіть страву',
    ru: 'Введите блюдо'
  },
  'general.offline': {
    en: 'offline',
    uk: 'оффлайн',
    ru: 'оффлайн'
  },
  'richMenu.notFound': {
    en: 'Nothing was found',
    uk: 'На жаль, нічого не знайдено',
    ru: 'К сожалению, ничего не найдено'
  },
  'subcat.all': {
    en: 'All',
    uk: 'Всi',
    ru: 'Все'
  },
  'waitingTimeBadge.outOfStock': {
    en: 'Out of stock',
    uk: 'ЗАКІНЧИЛОСЯ',
    ru: 'ЗАКОНЧИЛОСЬ'
  },
  'waitingTimeBadge.time': {
    en: 'min.',
    uk: 'хв.',
    ru: 'мин.'
  },
  'orderCard.loading': {
    en: 'Loading ...',
    uk: 'Завантаження ...',
    ru: 'Загрузка...'
  },
  'orderCard.repeat': {
    en: 'Repeat your order',
    uk: 'Повторити замовлення',
    ru: 'Повторить заказ'
  },
  'general.shortMonth': {
    en: 'Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec',
    uk: 'січ, лют, бер, кві, тра, чер, лип, серп, вер, жовт, лист, гру',
    ru: 'янв, фев, мар, апр, мая, июн, июл, авг, сен, окт, ноя, дек'
  },
  'general.longMonth': {
    en: 'January, February, March, April, May, June, July, August, September, October, November, December',
    uk: 'січня, лютого, березня, квітня, травня, червня, липня, серпня, вересня, жовтня, листопада, грудня',
    ru: 'января, февраля, марта, апреля, мая, июня, июля, августа, сентября, октября, ноября, декабря'
  },
  'paymentHistory.nothing': {
    en: 'No payments.',
    uk: 'Немає платежів.',
    ru: 'Нет платежей.'
  },
  'confirmationView.hereView.label': {
    en: 'We will bring your order in',
    uk: 'Ми принесемо замовлення за ваш столик через',
    ru: 'Мы принесём заказ за ваш столик через'
  },
  'confirmationView.takeoutView.label': {
    en: 'You can pick up your order in the pickup area',
    uk: 'Ви зможете забрати своє замовлення в зоні видачі',
    ru: 'Вы сможете забрать свой заказ в зоне выдачи'
  },
  'confirmationView.takeoutView.labelwithtable': {
    en: 'You will get your order at table {{stol}} in',
    uk: 'Принесемо ваше замовлення за столик {{stol}} за',
    ru: 'Принесем ваш заказ за столик {{stol}} через'
  },
  'general.after': {
    en: 'in',
    uk: 'за',
    ru: 'через'
  },
  'general.at': {
    en: 'at',
    uk: 'в',
    ru: 'в'
  },
  'general.tommorow': {
    en: 'Tomorrow',
    uk: 'Завтра',
    ru: 'Завтра'
  },
  'confirmationView.deliveryView.header': {
    en: 'Thank you. We received your order.',
    uk: 'Дякуємо, ваше замовлення прийняте.',
    ru: 'Спасибо, ваш заказ принят.'
  },
  'confirmationView.deliveryView.body': {
    en: 'We will send confirmation to your number',
    uk: 'Ми відправили вам підтвердження на номер',
    ru: 'Мы отправили вам подтверждение на номер'
  },
  'confirmationView.deliveryView.label': {
    en: 'We will call your back to clarify delivery detatils after your order would be finished',
    uk: 'Передзвонимо вам для уточнення доставки, коли замовлення буде готове',
    ru: 'Перезвоним вам для уточнения деталей доставки, когда заказ будет готов'
  },
  'general.table': {
    en: 'Table',
    uk: 'Стіл',
    ru: 'Стол'
  },
  'general.bench': {
    en: 'Bench',
    uk: 'Лавка',
    ru: 'Скамейка'
  },
  'general.ok': {
    en: 'OK',
    uk: 'OK',
    ru: 'OK'
  },
  'confirmationView.payment': {
    en: 'The payment was successful!',
    uk: 'Оплата пройшла успішно!',
    ru: 'Оплата прошла успешно!'
  },
  'confirmationView.orderNum': {
    en: 'Order number:',
    uk: 'Номер замовлення:',
    ru: 'Номер заказа:'
  },
  'calories.total': {
    en: 'kcal',
    uk: 'ккал',
    ru: 'ккал'
  },
  'calories.fat': {
    en: 'fat',
    uk: 'жири',
    ru: 'жиры'
  },
  'calories.carbs': {
    en: 'carbs',
    uk: 'вуглеводи',
    ru: 'углеводы'
  },
  'calories.protein': {
    en: 'protein',
    uk: 'білки',
    ru: 'белки'
  },
  'dishCard.error': {
    en: 'Error. Dish is not selected.',
    uk: 'Сталася помилка, страву не обрано!',
    ru: 'Произошла ошибка, блюдо не выбрано!'
  },
  'dishCard.description': {
    en: 'Description',
    uk: 'Опис',
    ru: 'Описание'
  },
  'dishCard.calories': {
    en: 'Calories',
    uk: 'Калорії',
    ru: 'Калории'
  },
  'sizeOptions.size': {
    en: 'Size',
    uk: 'Розмір',
    ru: 'Размер'
  },
  'contactUs.rateus': {
    en: 'How would you rate your experience?',
    uk: 'Як вам наш сервіс?',
    ru: 'Как вам наш сервис?'
  },
  'contactUs.rate1': {
    en: 'Awful',
    uk: 'Жахливо',
    ru: 'Ужасно'
  },
  'contactUs.rate2': {
    en: 'Poorly',
    uk: 'Погано',
    ru: 'Плохо'
  },
  'contactUs.rate3': {
    en: 'Fine',
    uk: 'Нормально',
    ru: 'Нормально'
  },
  'contactUs.rate4': {
    en: 'Good',
    uk: 'Добре',
    ru: 'Хорошо'
  },
  'contactUs.rate5': {
    en: 'Excellent',
    uk: 'Відмінно',
    ru: 'Отлично'
  },
  'contactUs.header': {
    en: 'We greatly value your opinion! Please, share your feedback 🙏🏻',
    uk: 'Нам завжди дуже важливо знати, як пройшла кожна наша зустріч. Тому розкажіть нам, будь ласка 🙏🏻',
    ru: 'Нам всегда очень важно знать, как прошла каждая наша встреча. Поэтому расскажите нам, пожалуйста 🙏🏻'
  },
  'contactUs.anon': {
    en: 'send anonymously',
    uk: 'відправити анонімно',
    ru: 'отправить анонимно'
  },
  'contactUs.otherContact': {
    en: 'Other ways of communication',
    uk: "Інші способи зв'язку",
    ru: 'Другие способы связи'
  },
  'contactUs.sendButton': {
    en: 'Send',
    uk: 'Надіслати',
    ru: 'Отправить'
  },
  'contactUs.thank': {
    en: 'Thank you for feedback!',
    uk: 'Дякую за ваш відгук!',
    ru: 'Спасибо за ваш отзыв!'
  },
  'contactUs.weread': {
    en: 'It will help us to improve our service.',
    uk: "Ми читаємо кожне повідомлення, і зворотний зв'язок допомагає нам ставати краще.",
    ru: 'Мы читаем каждое сообщение, и обратная связь помогает нам становиться лучше.'
  },
  'contactUs.leave': {
    en: 'Please, provide your contact info.',
    uk: "Залиште, будь ласка, ваші контактні дані, щоб ми могли зв'язатися з вами.",
    ru: 'Оставьте, пожалуйста, ваши контактные данные, чтобы мы могли связаться с вами.'
  },
  'contactUs.later': {
    en: 'Later',
    uk: 'Потім',
    ru: 'Потом'
  },
  'contactUs.now': {
    en: 'Enter',
    uk: 'Вказати',
    ru: 'Указать'
  },
  'contactUs.callus': {
    en: 'Call',
    uk: 'Зателефонувати',
    ru: 'Позвонить'
  },
  'contactUs.contactPhone': {
    en: '+380444660604',
    uk: '+380444660604',
    ru: '+380444660604'
  },
  'contactUs.contactTg': {
    en: 'khlbn_support',
    uk: 'khlbn_support',
    ru: 'khlbn_support'
  },
  'comment.button': {
    en: 'Enter',
    uk: 'Додати',
    ru: 'Добавить'
  },
  'comment.placeholder': {
    en: 'Provide some more details about your order',
    uk: 'Додайте уточнення до свого замовлення',
    ru: 'Добавьте уточнение к своему заказу'
  },
  'codeScan.notable': {
    en: 'Wrong table number!',
    uk: 'Код не містить номер столика!',
    ru: 'Код не содержит номер столика!'
  },
  'codeScan.scanQR': {
    en: 'Scan QR code',
    uk: 'Сканувати QR код',
    ru: 'Сканировать QR код'
  },
  'contactView.header': {
    en: 'Contact information',
    uk: 'Контактная информация',
    ru: 'Контактная информация'
  },
  'contactView.body': {
    en: 'Please, provide your contact information',
    uk: 'Укажите пожалуйста, как с вами связаться, в случае необходимости',
    ru: 'Укажите пожалуйста, как с вами связаться, в случае необходимости'
  },
  'contactView.button': {
    en: 'Continue',
    uk: 'Далі',
    ru: 'Далее'
  },
  'imherePopup.variab': {
    en: "I'm outside",
    uk: 'Жду снаружи',
    ru: 'Жду снаружи'
  },
  'imherePopup.header': {
    en: 'I have arrived',
    uk: 'Я уже здесь',
    ru: 'Я уже здесь'
  },
  'imherePopup.placeholder': {
    en: 'Add more datails about your order',
    uk: 'Добавьте уточнение к своему заказу',
    ru: 'Добавьте уточнение к своему заказу'
  },
  'changeLocationView.header': {
    en: 'Where do I order?',
    uk: 'Где я заказываю?',
    ru: 'Где я заказываю?'
  },
  'changeLocationView.body1': {
    en: 'You placing order at',
    uk: 'Вы размещаете заказ в заведении',
    ru: 'Вы размещаете заказ в заведении'
  },
  'changeLocationView.body2': {
    en: 'but closest restaurant is',
    uk: 'но ближайшее к вам заведение',
    ru: 'но ближайшее к вам заведение'
  },
  'changeLocationView.label': {
    en: 'Place order at closest?',
    uk: 'В каком заведении разместить заказ?',
    ru: 'В каком заведении разместить заказ?'
  },
  'changeLocationView.button': {
    en: 'Show the map',
    uk: 'Показати мапу',
    ru: 'Показать карту'
  },
  'changeLocationView.choice1': {
    en: 'Оставить текущий выбор, {{N}} м',
    uk: 'Оставить текущий выбор, {{N}} м',
    ru: 'Оставить текущий выбор, {{N}} м'
  },
  'changeLocationView.choice2': {
    en: 'Разместить в ближайшем, {{N}} м',
    uk: 'Разместить в ближайшем, {{N}} м',
    ru: 'Разместить в ближайшем, {{N}} м'
  },
  'general.yes': {
    en: 'Yes',
    uk: 'Так',
    ru: 'Да'
  },
  'general.no': {
    en: 'No',
    uk: 'Нi',
    ru: 'Нет'
  },
  'paymentSuccessView.body': {
    en: 'We will notify your when your order will be ready. Approximate waiting time is',
    uk: 'Мы пришлем уведомление, когда заказ будет готов. Примерное время приготовления',
    ru: 'Мы пришлем уведомление, когда заказ будет готов. Примерное время приготовления'
  },
  'paymentSuccessView.default': {
    en: '15min',
    uk: '15мiн',
    ru: '15мин'
  },
  'confirmationView.header': {
    en: 'Scan successful',
    uk: 'Сканирование успешно',
    ru: 'Сканирование успешно'
  },
  'confirmationView.button': {
    en: 'Ready',
    uk: 'Готово',
    ru: 'Готово'
  },
  'confirmNotify.body': {
    en: 'Message sent',
    uk: 'Ваше сообщение отправлено',
    ru: 'Ваше сообщение отправлено'
  },
  'confirmationViewNew.button.notable': {
    en: 'Enter table',
    uk: 'Додати стіл',
    ru: 'Добавить стол'
  },
  'confirmationViewNew.button.change': {
    en: 'Change',
    uk: 'Змінити',
    ru: 'Изменить'
  },
  'confirmationViewNew.header': {
    en: 'Scan successful',
    uk: 'Сканирование успешно',
    ru: 'Сканирование успешно'
  },
  'confirmationBenchScan.body': {
    en: 'Мы приготовим и принесем заказ прямо сюда (+3 минуты по сравнению с заказом в заведении)',
    uk: 'Мы приготовим и принесем заказ прямо сюда (+3 минуты по сравнению с заказом в заведении)',
    ru: 'Мы приготовим и принесем заказ прямо сюда (+3 минуты по сравнению с заказом в заведении)'
  },
  'scanView.hint1': {
    en: 'Place the code image in the viewfinder frames, reading will be automatic',
    uk: 'Помістіть зображення коду в рамки видошукача, зчитування відбудеться автоматично',
    ru: 'Поместите изображение кода  в рамки видоискателя, считывание произойдет автоматически'
  },
  'scanView.manual': {
    en: 'Or enter the number manually',
    uk: 'Або вкажіть номер вручну',
    ru: 'Или укажите номер вручную'
  },
  'checkout.paymethod': {
    en: 'Determining payment method',
    uk: 'Визначаємо спосіб розрахунку',
    ru: 'Определяем способы оплаты'
  },
  'checkout.stoplist': {
    en: 'Some dishes are not available',
    uk: 'Деяких позицій немає в наявності',
    ru: 'Некоторых позиций нет в наличии'
  },
  end: {
    en: ' App.',
    uk: ' додаток.',
    ru: ' приложение.'
  },
  selectLanguage: {
    en: 'Select language',
    uk: 'Виберіть мову',
    ru: 'Выберите язык'
  },
  'profile.yourdata': {
    en: 'Your information was saved',
    uk: 'Ваши данные сохранены',
    ru: 'Ваши данные сохранены'
  },
  'profile.wecant': {
    en: 'It is impossible to send SMS to this number',
    uk: 'Мы не сможем отправить вам СМС на этот номер телефона',
    ru: 'Мы не сможем отправить вам СМС на этот номер телефона'
  },
  'profile.skip': {
    en: 'Skip',
    uk: 'Пропустить',
    ru: 'Пропустить'
  },
  'orderSection.almostReady': {
    en: 'We are already placing an order',
    uk: 'Вже виносимо замовлення',
    ru: 'Уже выносим заказ'
  }
}

// const initialState = translation
const initialState = { common: {}, brand: {}, venues: [] }

const slice = createSlice({
  name: 'texts',
  initialState,
  reducers: {
    setCommon(state, action) {
      state.common = action.payload
    },
    setBrand(state, action) {
      state.brand = action.payload
    },
    setVenues(state, action) {
      state.venues = action.payload
    }
  }
})

export const reducer = slice.reducer

const authHeader = {
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

export const fetchCommonTexts = (newBrand = null) => async dispatch => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/translations/common`
  const response = await axios.get(url, authHeader)
  dispatch(slice.actions.setCommon(response.data.brand))
}

export const fetchUncommonTexts = (newBrand = null) => async dispatch => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/translations`
  const response = await axios.get(url, authHeader)

  dispatch(slice.actions.setBrand(response.data.brand))
  dispatch(slice.actions.setVenues(response.data.venues))
}

export const updateTextsApi = async (venue, newBrand) => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/translations`
  const body = venue
  const response = await axios
    .put(url, body, authHeader)
    .then(e => e)
    .catch(err => ({ status: 'fail' }))
  return response
}

export const selectTexts = state => state.texts
export const selectTextsCommon = state => state.texts.common
export const selectTextsBrand = state => state.texts.brand
export const selectTextsVenues = state => state.texts.venues

export default slice
