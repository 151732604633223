import { Backdrop, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
  loaderBackdrop: {
    zIndex: 99,
    backgroundColor: 'rgba(255, 255, 255, 0.5)'
  }
}))

const StyledBackdrop = props => {
  const classes = useStyles()

  return <Backdrop className={classes.loaderBackdrop} {...props} />
}

export default StyledBackdrop
