import {
  createDishError,
  createDishStarted,
  createDishSuccess,
  getDishesError,
  getDishesStarted,
  getDishesSuccess,
  updateDishError,
  updateDishStarted,
  updateDishSuccess
} from './Dishes.actions'
import { request } from 'src/api/request'

export const getDishesThunk = () => async dispatch => {
  try {
    dispatch(getDishesStarted())
    const response = await request('/dishes')

    dispatch(getDishesSuccess(response.data))
  } catch (error) {
    dispatch(getDishesError(error.message))
  }
}

export const createDishThunk = dish => async dispatch => {
  try {
    dispatch(createDishStarted(dish))
    const createdDish = await request('/dishes', {
      method: 'POST',
      data: dish
    })

    dispatch(createDishSuccess(createdDish))
  } catch (error) {
    dispatch(createDishError(error.message))
  }
}

export const updateDishThunk = dish => async dispatch => {
  try {
    dispatch(updateDishStarted(dish.id))
    const updatedDish = await request('/dishes', {
      method: 'PATCH',
      data: dish
    })

    dispatch(updateDishSuccess(updatedDish))
  } catch (error) {
    dispatch(updateDishError(error.message))
  }
}

export const removeDishMediaThunk = dishId => async (dispatch, getState) => {
  const { dishes } = getState()
  const dish = dishes.dishes[dishId]

  if (dish) {
    dispatch(
      updateDishSuccess({
        ...dish,
        previewMedia: []
      })
    )
  }
}

export const removeDishMediaItemThunk = (dishId, fileId) => async (dispatch, getState) => {
  const { dishes } = getState()
  const dish = dishes.dishes[dishId]

  if (dish && fileId) {
    dispatch(
      updateDishSuccess({
        ...dish,
        media: dish.media.filter(file => file.id !== fileId)
      })
    )
  }
}
