const translationsClientUi = {
  ru: {
    appGuest: 'Приложение гостя',
    appearance: 'Внешний вид',
    save: 'Сохранить',
    reset: 'Сбросить',
    select: 'Выбрать',
    template: 'Шаблон',
    logo: 'Логотип',
    colors: 'Цвета',
    icons: 'Иконки',
    branding: 'Брендирование',
    copyright: 'Копирайтинг',
    extra: 'Дополнительно',
    name: 'Имя',
    lastChange: 'Последнее изменение',
    naming: 'Название',
    logoNameForCustomer:
      'Название заведения, которое будет отображаться в приложении. Для использования фирменного начертание - загрузите его ниже.',
    hlbn: 'ХЛБНЙ',
    fullLogo: 'Полный логотип',
    descForLogo:
      'Официальный логотип сети, где присутствует знак/логотип и начертание/название. Обычно используется на экране запуска приложения или на онбординге',
    sign: 'Знак',
    descForLogoOnly:
      'Только знак без названия, используется повсеместно, например на иконке приложения, для брендинга или как иконка на карте',
    drawing: 'Начертание',
    descForDrawingOnly:
      'Название как часть логотипа, или являющееся логотипом может использоваться везде где упоминается название ресторана, например в шапке приложения',
    brandPattern: 'Бренд-паттерн',
    descBrandPattern:
      'Фирменный узор, используемый для идентификации бренда, если он есть имеет более широкое применение для брендинга чем знак: 1, 2, 3',
    colorAccent: 'Цвет акцента',
    colorAccentDesc: 'Подсветка всех активных элементов. Если не задан используется фирменный цвет',
    colorSuccess: 'Цвет успеха',
    colorSuccessDesc: 'Подсветка всех активных элементов. Если не задан используется фирменный цвет',
    warningColor: 'Цвет ошибки / предупреждения',
    warningColorDesc: 'Подсветка всех активных элементов. Если не задан используется фирменный цвет',
    filter: 'Фильтр',
    copyrightDesc:
      'Если в фильтре выбраны конкретные заведения, то изменение текстов приведет к созданию кастомных версий данных текстов для этих заведений. Тексты по-умолчанию останутся не тронутыми, для возврата к ним – кнопка',
    forAllBrand: 'Для всего бренда',
    status: 'Статус',
    blank: 'Незаполненные',
    filledIn: 'Заполненные',
    type: 'Тип',
    dialogs: 'Диалоги',
    others: 'Прочее',
    confirmDeleteTheme: 'Вы уверенны что хотите удалить выбранную тему?',
    confirmDeletePage: 'Вы уверенны что хотите удалить выбранную страницу?',
    activate: 'Активировать',
    deactivate: 'Деактивиривать',
    close: 'Закрыть',
    yes: 'Да',
    recommended: 'Рекомендуемые',
    dark: 'Темная',
    light: 'Светлая',
    change: 'Изменить',
    delete: 'Удалить',
    duplicate: 'Дублировать',
    mainBackground: 'Главный фон',
    navbarBackground: 'Фон навбара',
    dishCardBackground: 'Фон карточки блюда',
    shoppingCartBackground: 'Фон просмотра заказа',
    mainBackgroundApp: 'Главный фон приложения',
    backgroundColor1: 'Цвет фона 1',
    backgroundColorOfInnerContainersWindows: 'Цвет фона внутренних контейнеров, окон и т.д.',
    fontColor: 'Цвет шрифта',
    borderRadius: 'Округление углов',
    richMenuMarkerRadius: 'Округление углов маркера активной категории',
    richMenuMarkerBg: 'Фон маркера активной категории',
    richMenuIconColor: 'Цвет иконки активной категории',
    richMenuMarkerShadow: 'Границы маркера активной категории',
    mainFontColor: 'Основной цвет шрифта',
    attentionColor: 'Цвет Внимание',
    attentionColorDesc:
      'Этим цветом будут подсвечены хххххх требующие вмешательства гостя. Например добавленные в корзину, но не оплаченые блюда, успевшие попасть в стоп-лист и пр.',
    editTheme: 'Редактирование темы',
    createTheme: 'Создание темы',
    allSave: 'Все сохранено',
    published: 'Опубликовано',
    unsavedChanges: 'Есть несохраненные изменения',
    publish: 'Опубликовать',
    showBrandColor: 'Показать фирменный цвет',
    themeColor: 'Цвет темы',
    brandColor: 'Фирменный цвет',
    themeSuccess: 'Тема успешно',
    pageSuccess: 'Страница успешно',
    created: 'создана',
    create: 'создать',
    updated: 'обновлена',
    errorOccurredPleaseTryAgain: 'Произошла ошибка, попробуйте еще раз',
    deleted: 'удалена',
    errorDeleting: 'Произошла ошибка при удалении',
    popularIcon: 'Иконка популярных блюд',
    favoriteIcon: 'Иконка избранных блюд',
    navbar: 'Навбар',
    dishcard: 'Страница блюда',
    commonPages: 'Главное меню и внутренние страницы',
    brand_name: 'Название бренда',
    brand_sign: 'Логотип',
    branding_brand_sign: 'Знак',
    branding_logo: 'Логотип',
    customPages: 'Кастомные страницы',
    customPageIcon: 'Иконка страницы в меню приложения',
    customPageName: 'Название',
    customPageVisibility: 'Видимость',
    allVenuesVisible: 'Для всех заведений',
    customPageContent: 'Контент',
    venuesList: 'Список заведений',
    customPagesTopLevel: 'Верхнего уровня',
    customPagesOther: 'Прочие',
    customPageLabel: 'Лейбл в меню',
    customPageSlug: 'Уникальный идентификатор',
    labelActive: 'Активно',
    labelNotActive: 'Не активно',
    fieldIsRequired: 'Это поле обязательное'
  },
  uk: {
    appGuest: 'Додаток гостя',
    appearance: 'Зовнішній вигляд',
    save: 'Зберегти',
    reset: 'Скинути',
    select: 'Обрати',
    template: 'Шаблон',
    logo: 'Логотип',
    colors: 'Кольори',
    icons: 'Іконки',
    branding: 'Брендування',
    copyright: 'Копірайтинг',
    extra: 'Додатково',
    name: "Ім'я",
    lastChange: 'Остання зміна',
    naming: 'Назва',
    logoNameForCustomer:
      'Назва закладу, яке буде відображатися у додатку. Для використання фірмового начертання - завантажте його нижче.',
    hlbn: 'ХЛБНЙ',
    fullLogo: 'Повний логотип',
    descForLogo:
      'Офіційний логотип мережі, де присутній знак/логотип та начертання/назви. Звичайно використовується на екрані старту додатку або на онбордінге',
    sign: 'Знак',
    descForLogoOnly:
      'Тілько знак без назви, використовується скрізь, наприклад на іконці додатку, для брендування, або як іконка на мапі.',
    drawing: 'Начертання',
    descForDrawingOnly:
      'Назва як частина логотипу, або являється логотипом, може використовуватися скрізь де є назва ресторану, наприклад у шапці додатку',
    brandPattern: 'Бренд-паттерн',
    descBrandPattern:
      'Фірмовий визирунок, який використовується для ідентификації бренду, якщо він є та має більш розгалужене застосування для брендування ніж знак: 1, 2, 3',
    colorAccent: 'Колір акценту',
    colorAccentDesc: 'Підсвітка усіх активних елементів. Якщо не задано використовуеться фірмовий колір',
    colorSuccess: 'Колір успіху',
    colorSuccessDesc: 'Підсвітка усіх активних елементів. Якщо не задано використовуеться фірмовий колір',
    warningColor: 'Колір помилки / попередження',
    warningColorDesc: 'Підсвітка усіх активних елементів. Якщо не задано використовуеться фірмовий колір',
    filter: 'Фільтр',
    copyrightDesc:
      'Якщо у фільтрі обрани конкретні заклади, то редагування текстів призведе до створення кастомних версій даних текстів для ціх закладів. Тексти за замовчуванням лишаться сталими, для повернення до них – кнопка',
    forAllBrand: 'Для усього бренду',
    status: 'Статус',
    blank: 'Незаповнені',
    filledIn: 'Заповнені',
    type: 'Тип',
    dialogs: 'Діалоги',
    others: 'Інше',
    confirmDeleteTheme: 'Ви впевнені що бажаєте видалити обрану тему?',
    confirmDeletePage: 'Ви впевнені що бажаєте видалити обрану сторінку?',
    activate: 'Активувавати',
    deactivate: 'Деактивувати',
    close: 'Закрити',
    yes: 'Так',
    recommended: 'Рекомендовані',
    dark: 'Темна',
    light: 'Світла',
    change: 'Змінити',
    delete: 'Видалити',
    duplicate: 'Дублювати',
    mainBackground: 'Головний фон',
    navbarBackground: 'Фон навбару',
    dishCardBackground: 'Фон картки страви',
    shoppingCartBackground: 'Фон перегляду замовлення',
    mainBackgroundApp: 'Головний фон додатку',
    backgroundColor1: 'Колір фону 1',
    backgroundColorOfInnerContainersWindows: 'Колір фону внутрішніх контейнерів, вікон і т.д.',
    fontColor: 'Колір шрифту',
    borderRadius: 'Округлення кутів',
    richMenuMarkerRadius: 'Округлення кутів маркера активної категорії',
    richMenuMarkerBg: 'Фон маркера активної категорії',
    richMenuIconColor: 'Колір іконки активної категорії',
    richMenuMarkerShadow: 'Межі маркера активної категорії',
    mainFontColor: 'Основний колір шрифту',
    attentionColor: 'Колір Увага',
    attentionColorDesc:
      'Цім кольором будуть підсвічені хххххх які вимагають втручання гостя. Наприклад додавання до кошику, але не сплачені страви, які встигли потрапити до стоп-листа та пр.',
    editTheme: 'Редагування теми',
    createTheme: 'Створення теми',
    allSave: 'Все збережено',
    published: 'Опубліковано',
    unsavedChanges: 'Є незбереженні зміни',
    publish: 'Опублікувати',
    showBrandColor: 'Показати фірмовий колір',
    themeColor: 'Колір теми',
    brandColor: 'Фірмовий колір',
    themeSuccess: 'Тема успішно',
    pageSuccess: 'Сторінка успішно',
    created: 'створена',
    create: 'створити',
    updated: 'оновлена',
    errorOccurredPleaseTryAgain: 'Сталася помилка, спробуйте ще раз',
    deleted: 'видалена',
    errorDeleting: 'Сталася помилка при видаленні',
    popularIcon: 'Іконка популярних страв',
    favoriteIcon: 'Іконка улюблених страв',
    navbar: 'Навбар',
    dishcard: 'Сторінка страви',
    commonPages: 'Головне меню та внутрішні сторінки',
    brand_name: 'Назва бренду',
    brand_sign: 'Логотип',
    branding_brand_sign: 'Знак',
    branding_logo: 'Логотип',
    customPages: 'Кастомні сторінки',
    customPageIcon: 'Іконка сторінки в меню додатку',
    customPageName: 'Назва',
    customPageVisibility: 'Видимість',
    allVenuesVisible: 'Для всіх закладів',
    customPageContent: 'Контент',
    venuesList: 'Список закладів',
    customPagesTopLevel: 'Верхнього рівня',
    customPagesOther: 'Інші',
    customPageLabel: 'Лейбл у меню',
    customPageSlug: 'Унікальний ідентифікатор',
    labelActive: 'Активна',
    labelNotActive: 'Не активна',
    fieldIsRequired: "Це поле обов'язкове"
  }
}
export default translationsClientUi
