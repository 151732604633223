import { Avatar, Box, ButtonBase, Hidden, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useRef, useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import useAuth from 'src/hooks/useAuth'
import { selectBrandOverload } from 'src/slices/brands'
import { useSelector } from 'src/store'
import { deleteToken } from 'src/api'

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}))

const Account = () => {
  const classes = useStyles()
  const history = useHistory()
  const overloadedBrand = useSelector(selectBrandOverload)
  const ref = useRef(null)
  const { user, logout } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    try {
      handleClose()
      await deleteToken()
      window.location.pathname = '/'
      window.location.reload()
    } catch (err) {
      console.error(err)
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      })
    }
  }

  return (
    <>
      <Box display='flex' alignItems='center' component={ButtonBase} onClick={handleOpen} ref={ref}>
        <Avatar alt='User' className={classes.avatar} src={user.avatar} />
        <Hidden smDown>
          <Typography variant='h6' color='inherit'>
            {user.name} {overloadedBrand}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        {/* <MenuItem component={RouterLink} to="/app/social/profile">
          Profile
        </MenuItem>
        <MenuItem component={RouterLink} to="/app/account">
          Account
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  )
}

export default Account
