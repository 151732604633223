import React from 'react'
import { CircularProgress } from '@material-ui/core'
import StyledBackdrop from 'src/components/StyledBackdrop'

const LoadingSpinner = ({ loading, position }) => (
  <StyledBackdrop open={loading} style={{ position }}>
    <CircularProgress variant='indeterminate' size='3rem' />
  </StyledBackdrop>
)

export default LoadingSpinner
