import { getCurrentBrandStarted, getCurrentBrandSuccess, getCurrentBrandError } from './actions'
import { fetchBrandCustomizationSettings, fetchCurrentBrand } from 'src/api'

export const getCurrentBrand = () => async dispatch => {
  dispatch(getCurrentBrandStarted())
  const brand = await fetchCurrentBrand()
  let settings = null

  try {
    settings = await fetchBrandCustomizationSettings()
  } catch (e) {
    dispatch(getCurrentBrandError(e.message))
  }

  if (brand) {
    dispatch(
      getCurrentBrandSuccess({
        ...(brand ? brand : {}),
        ...(settings ? { customizationSettings: settings } : {})
      })
    )
  } else {
    dispatch(getCurrentBrandError('Brand fetching error'))
  }
}
