import { combineReducers } from '@reduxjs/toolkit'
import categoriesReducer from 'src/store/categories/Categories.reducer'
import { reducer as menusReducer } from 'src/slices/menus'
import { reducer as modifiersReducer } from 'src/slices/modifiers'
import { reducer as settingsReducer } from 'src/slices/settings'
import { reducer as optionsetsReducer } from 'src/slices/optionsets'
import { reducer as brandsReducer } from 'src/slices/brands'
import { reducer as venuesReducer } from 'src/slices/venues'
import { reducer as integrationsReducer } from 'src/slices/integrations'
import { reducer as textsReducer } from 'src/slices/texts'
import { ThemesReducer } from 'src/store/themes/Themes.reducer'
import dishesReducer from './dishes/Dishes.reducer'
import usersReducer from './users/reducer'
import currentBrandReducer from './currentBrand/reducer'
import generalReducer from './general/reducers'
import tagsReducer from './tags/tags.reducer'

const rootReducer = combineReducers({
  brands: brandsReducer,
  categories: categoriesReducer,
  dishes: dishesReducer,
  integrations: integrationsReducer,
  texts: textsReducer,
  menus: menusReducer,
  modifiers: modifiersReducer,
  settings: settingsReducer,
  optionsets: optionsetsReducer,
  themes: ThemesReducer,
  users: usersReducer,
  venues: venuesReducer,
  general: generalReducer,
  currentBrand: currentBrandReducer,
  tags: tagsReducer
})

export default rootReducer
