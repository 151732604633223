import { createSelector, createSlice } from '@reduxjs/toolkit'
// import axios from 'axios'
import { config } from 'src/constants'
import axios from 'src/utils/axios'
import { idsFromEntities } from 'src/utils/idsFromEntities'
import objFromArray from 'src/utils/objFromArray'

const initialState = {
  entities: {},
  ids: []
}

const slice = createSlice({
  name: 'modifiers',
  initialState,
  reducers: {
    setModifiers(state, action) {
      state.entities = objFromArray(action.payload)
      state.ids = idsFromEntities({ entities: state.entities })
    }
  }
})

const entitiesToArr = state => {
  return state.ids.map(id => state.entities[id])
}

export const reducer = slice.reducer

const authHeader = {
  withCredentials: true,
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

export const fetchModifiers = (newBrand = null) => async dispatch => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/modifiers`
  const response = await axios.get(url, authHeader)

  dispatch(slice.actions.setModifiers(response.data.result))
}

export const postModApi = async (venue, newBrand = null) => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/modifiers`
  const body = venue
  const response = await axios
    .post(url, body, authHeader)
    .then(e => e)
    .catch(err => ({ status: 'fail' }))
  return response
}

export const patchModApi = async (venue, newBrand = null) => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/modifiers/${venue.id}`
  const body = venue
  const response = await axios
    .patch(url, body, authHeader)
    .then(e => e)
    .catch(err => ({ status: 'fail' }))
  return response
}

export const selectModSlice = state => state.modifiers
export const selectModEntities = createSelector(selectModSlice, x => x.entities)
export const selectAllModifiers = createSelector(selectModSlice, entitiesToArr)
export const selectModById = id => createSelector(selectModEntities, x => x[id])

export default slice
