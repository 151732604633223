import axios from 'src/utils/axios'
import { authHeader } from './index'
import { config } from 'src/constants'

export const request = async (endpoint, options) => {
  const { method, data, params, path } = options || {}

  const URL = `${config.API_URL3}/backoffice/${window.busytable.brandSlug}${endpoint}${path || ''}`

  const REQUEST_CONFIG = {
    ...authHeader
  }

  switch (method) {
    case 'POST':
      return axios
        .post(
          URL,
          { ...data },
          {
            ...REQUEST_CONFIG
          }
        )
        .then(res => res.data)
    case 'PATCH':
      return axios
        .patch(
          URL,
          { ...data },
          {
            ...REQUEST_CONFIG
          }
        )
        .then(res => res.data)
    case 'PUT':
      return axios
        .put(
          URL,
          { ...data },
          {
            ...REQUEST_CONFIG
          }
        )
        .then(res => res.data)
    case 'GET':
      return axios
        .get(URL, {
          ...REQUEST_CONFIG
        })
        .then(res => res.data)
    case 'DELETE':
      return axios
        .delete(URL, {
          ...REQUEST_CONFIG,
          data: data || undefined,
          params: params || undefined
        })
        .then(res => res.data)
    default:
      return axios
        .get(URL, {
          ...REQUEST_CONFIG
        })
        .then(res => res.data)
  }
}
