import { createSelector, createSlice } from '@reduxjs/toolkit'
// import axios from 'axios'
import { config } from 'src/constants'
import axios from 'src/utils/axios'
import { idsFromEntities } from 'src/utils/idsFromEntities'
import objFromArray from 'src/utils/objFromArray'

const initialState = {
  entities: {},
  ids: [],
  loading: true
}

const slice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    setMenus(state, action) {
      state.entities = objFromArray(action.payload)
      state.ids = idsFromEntities({ entities: state.entities })
      state.loading = false
    },
    deleteMenu(state, action) {
      delete state.entities[action.payload]
      state.ids = state.ids.filter(id => id !== action.payload)
    },
    updateMenu(state, action) {
      state.entities[action.payload.id] = action.payload
    }
  }
})

const entitiesToArr = state => {
  return state.ids.map(id => state.entities[id])
}

export const reducer = slice.reducer

const authHeader = {
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

export const fetchMenus = newBrand => async dispatch => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/menu`
  const response = await axios.get(url, authHeader)

  dispatch(slice.actions.setMenus(response.data))
}

export const createMenuApi = async (menu, newBrand) => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/menu`
  const body = menu
  const response = await axios
    .post(url, body, authHeader)
    .then(e => e)
    .catch(err => ({ status: 'fail' }))
  return response
}

export const editMenuApi = (menu, newBrand) => async dispatch => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/menu`
  await axios.patch(url, menu, authHeader)
  dispatch(slice.actions.updateMenu(menu))
}

export const duplicateMenuApi = async (menu, newBrand) => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/menu/duplicate/${
    menu.id
  }`
  const body = menu
  const response = await axios
    .post(url, body, authHeader)
    .then(e => e)
    .catch(err => ({ status: 'fail' }))
  return response
}

export const deleteMenuApi = (menu, newBrand) => async dispatch => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/menu`
  const data = {
    id: menu.id
  }

  await axios.delete(url, { data, ...authHeader })
  dispatch(slice.actions.deleteMenu(menu.id))
}

export const selectMenuSlice = state => state.menus

export const selectMenuLoading = createSelector(selectMenuSlice, x => x.loading)

export const selectAllMenus = createSelector(selectMenuSlice, entitiesToArr)
export const selectMenuEntities = createSelector(selectMenuSlice, x => x.entities)
export const selectMenuById = id => createSelector(selectMenuEntities, x => x[id])

export default slice
