import { Container, makeStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import Page from 'src/components/Page'
import { selectDishesLoading } from 'src/store/dishes/Dishes.selectors'
import { useSelector } from 'src/store'
import Header from 'src/components/Header'
import Results from './Results'
import { getSelectedLanguage } from 'src/store/general/selectors'
import translationsDishes from 'src/translations/dishes'
import LoadingSpinner from '../../../components/UI/LoadingSpinner'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}))

const DishListView = () => {
  const classes = useStyles()
  const dishesLoading = useSelector(selectDishesLoading)
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsDishes[selectedLanguage]

  return (
    <Page className={classes.root} title={translations.listDishes}>
      <Container maxWidth={false}>
        <Header
          breadcrumbStep1={translations.venue}
          linkButton={`/app/dishes_create`}
          create={true}
          titleSection={translations.dishes}
          labelButton={translations.dish}
        />
        <Results />
        <LoadingSpinner loading={dishesLoading} />
      </Container>
    </Page>
  )
}

export default DishListView
