import { useEffect } from 'react'
import { fetchMenus } from 'src/slices/menus'
import { fetchModifiers } from 'src/slices/modifiers'
import { fetchOptionsets } from 'src/slices/optionsets'
import { isSuperAdmin } from 'src/slices/users'
import { fetchVenues } from 'src/slices/venues'
import { useDispatch, useSelector } from 'src/store'
import { fetchIntegrationExt } from 'src/slices/integrations'
import { fetchCommonTexts, fetchUncommonTexts } from 'src/slices/texts'
import { fetchBrands, selectBrandOverload } from 'src/slices/brands'
import useAuth from 'src/hooks/useAuth'
import { fetchUsersV3 } from '../slices/users'
import { getActiveThemeThunk } from 'src/store/themes/Themes.thunkActions'
import { setLanguages } from 'src/slices/settings'
import { getLanguages } from 'src/api'
import { getDishesThunk } from '../store/dishes/Dishes.thunkActions'
import { getTagsThunk } from '../store/tags/tags.thunkActions'
import { getCategoriesThunk } from '../store/categories/Categories.thunkActions'

export const Api = () => {
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuth()
  const isa = useSelector(isSuperAdmin)
  const overloadedBrand = useSelector(selectBrandOverload)

  useEffect(() => {
    if (!!isAuthenticated) {
      dispatch(getCategoriesThunk())
      dispatch(getDishesThunk())
      dispatch(fetchCommonTexts(overloadedBrand))
      dispatch(fetchUncommonTexts(overloadedBrand))
      dispatch(fetchIntegrationExt(overloadedBrand))
      dispatch(fetchMenus(overloadedBrand))
      dispatch(fetchModifiers(overloadedBrand))
      dispatch(fetchUsersV3(overloadedBrand))
      dispatch(fetchVenues(overloadedBrand))
      dispatch(fetchOptionsets(overloadedBrand))
      dispatch(getActiveThemeThunk())
      dispatch(getTagsThunk())
      async function fetchLanguagesSettings() {
        const resp = await getLanguages()
        if (resp) {
          dispatch(setLanguages(Object.keys(resp.languages)))
        }
      }
      fetchLanguagesSettings()
    }
  }, [dispatch, isAuthenticated, overloadedBrand])

  if (isa) {
    dispatch(fetchBrands())
  }

  return null
}
