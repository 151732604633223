import React from 'react'
import { Backdrop, Box, CircularProgress, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { getSelectedLanguage } from 'src/store/general/selectors'
import translationsClientUi from 'src/translations/clientUi'

const useStyles = makeStyles(theme => ({
  colorSwatch: {
    minWidth: '32px',
    minHeight: '32px',
    borderRadius: '2px',
    marginRight: '8px',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)'
  },
  recommendedAccent: {
    marginTop: '16px'
  },
  recommendedAccentColor: {
    marginRight: '16px',
    cursor: 'pointer'
  },
  loaderBackdrop: {
    zIndex: 99,
    backgroundColor: 'rgba(255, 255, 255, 0.5)'
  },
  headerStatusIcon: {
    width: '16px',
    marginRight: '8px'
  },
  inputBox: {
    maxWidth: '520px'
  }
}))

export const ColorSwatch = ({ className, ...rest }) => {
  const classes = useStyles()
  const rootClass = clsx(classes.colorSwatch, className)

  return <div className={rootClass} {...rest} />
}

export const RecommendedColors = ({ colors, handleSelect }) => {
  const classes = useStyles()
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsClientUi[selectedLanguage]

  return (
    <Box className={classes.recommendedAccent}>
      <Typography variant='subtitle2' gutterBottom>
        {translations.recommended}
      </Typography>
      <Box display='flex'>
        {colors.map((color, i) => (
          <ColorSwatch
            key={`color-${i}`}
            className={classes.recommendedAccentColor}
            onClick={() => handleSelect(color)}
            style={{ backgroundColor: color }}
          />
        ))}
      </Box>
    </Box>
  )
}

export const HeaderStatusIcon = ({ icon: Icon, className, ...rest }) => {
  const classes = useStyles()
  const rootClass = clsx(classes.headerStatusIcon, className)

  return <Icon className={rootClass} {...rest} />
}

export const StyledBackdrop = ({ absolute, ...rest }) => {
  const classes = useStyles()

  return (
    <Backdrop
      className={classes.loaderBackdrop}
      style={{
        position: absolute ? 'absolute' : null
      }}
      {...rest}
    />
  )
}

export const InputBox = props => {
  const classes = useStyles()

  return <div className={classes.inputBox} {...props} />
}
