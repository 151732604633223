export const APP_VERSION = '3.1.0'

export const ENABLE_REDUX_DEV_TOOLS = true

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
}

export const ASSET_TYPE = {
  COMMON: 'common',
  ICONS: 'icons',
  DISHES: 'dishes',
  BRAND: 'brand'
}

export const NAVBAR_TITLE_TYPE = {
  BRAND_LOGO: 'brand_sign',
  BRAND_NAME: 'brand_name'
}

export const PAGE_BRANDING_TYPE = {
  BRAND_SIGN: 'brand_sign',
  BRAND_LOGO: 'logo'
}

export const THEME_EDIT_TAB_NAMES = {
  template: 'template',
  colors: 'colors',
  icons: 'icons',
  branding: 'branding',
  copyright: 'copyright',
  extra: 'extra'
}

export const dishVolumeTypes = ['unit_g', 'unit_pc', 'unit_ml', 'unit_kg']
export const TIME_UNITS = ['hours', 'minutes', 'days']

export const mediaUrl = url =>
  url ? `https://${window.mode !== 'production' ? 'dev.' : ''}${window.busytable.domain}${url}` : ''

export const settings = {
  production: {
    API_URL: `https://api.${window.busytable.domain}/v2`,
    API_URL3: `https://api.${window.busytable.domain}/v3`,
    API_URL4: `https://api.${window.busytable.domain}/v4`,
    CHANNEL_URL: `https://api.${window.busytable.domain}/v3`,
    ORIGIN_URL: `https://${window.busytable.domain}`
  },
  staging: {
    API_URL: `https://api.${window.busytable.domain}/v2`,
    API_URL3: `https://api.${window.busytable.domain}/v3`,
    API_URL4: `https://api.${window.busytable.domain}/v4`,
    CHANNEL_URL: `https://api.${window.busytable.domain}/v3`,
    ORIGIN_URL: `https://${window.busytable.domain}`
  },
  develop: {
    API_URL: `https://devapi.${window.busytable.domain}/v2`,
    API_URL3: `https://devapi.${window.busytable.domain}/v3`,
    API_URL4: `https://devapi.${window.busytable.domain}/v4`,
    CHANNEL_URL: `https://devapi.${window.busytable.domain}/v3`,
    ORIGIN_URL: `https://dev.${window.busytable.domain}`
  },
  localdev: {
    API_URL: 'http://localhost:5000/v2',
    API_URL3: 'http://localhost:5000/v3',
    API_URL4: 'http://localhost:5000/v4',
    CHANNEL_URL: 'http://localhost:5001/v3',
    ORIGIN_URL: 'http://localhost:3000'
  },
  remotedev: {
    API_URL: 'http://192.168.0.103:3002/v2',
    API_URL3: 'http://192.168.0.103:3002/v3',
    API_URL4: 'http://localhost:5000/v4',
    CHANNEL_URL: 'http://192.168.0.103:3002/v3',
    ORIGIN_URL: 'http://192.168.0.103:3002'
  }
}

//window.mode
// export const config = process.env.REACT_APP_DEV ? dev : prod

export const config = settings[window.mode]
