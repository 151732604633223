const filterParentSize = dish => dish.parentSizeId === null

export const selectDishesLoading = store => store.dishes.loading

export const selectDishesError = store => store.dishes.errorMessage

export const selectDishEntities = store => store.dishes.dishes

export const selectDishById = id => store => store.dishes.dishes[id]

export const selectAllDishes = store => Object.values(store.dishes.dishes)

export const selectDishesWithoutSizes = store => Object.values(store.dishes.dishes).filter(filterParentSize)
