/**
 * receives etitites object and optional comparison function
 * returns sorted array of ids
 *
 * @param {Object} entities
 */
export const idsFromEntities = ({ entities, compareFunc = null, key = 'id' }) => {
  const defaultCompareFunc = (a, b) => (a.id > b.id ? 1 : -1)
  const arr = Object.values(entities)

  return arr.sort(compareFunc || defaultCompareFunc).map(x => x[key])
}
