export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_STARTED = 'GET_CATEGORIES_STARTED'
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR'
export const UPDATE_CATEGORY_STARTED = 'UPDATE_CATEGORY_STARTED'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR'
export const ADD_CATEGORY_STARTED = 'ADD_CATEGORY_STARTED'
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS'
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR'
export const DELETE_CATEGORY_STARTED = 'DELETE_CATEGORY_STARTED'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR'
export const RESET_CATEGORY_EDITING = 'RESET_CATEGORY_EDITING'
