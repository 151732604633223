/* eslint-disable no-use-before-define */
import { Box, Drawer, Hidden, List, ListSubheader, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom'
import Logo from 'src/components/Logo'
import useAuth from 'src/hooks/useAuth'
import NavItem from './NavItem'
import { useSelector } from 'src/store'
import { ReactComponent as SlidersIcon } from 'src/assets/icons/sliders.svg'
import { ReactComponent as StarIcon } from 'src/assets/icons/star.svg'
import { ReactComponent as TagIcon } from 'src/assets/icons/tag.svg'
import { ReactComponent as UsersIcon } from 'src/assets/icons/users.svg'
import { ReactComponent as OverviewIcon } from 'src/assets/icons/clipboard.svg'
import { ReactComponent as RestaurantIcon } from 'src/assets/icons/restaurant.svg'
import { ReactComponent as RestaurantMenuIcon } from 'src/assets/icons/menu.svg'
import { ReactComponent as IcDishes } from 'src/assets/icons/dish.svg'
import { ReactComponent as IcModifiers } from 'src/assets/icons/mody.svg'
import { ReactComponent as QrIcon } from 'src/assets/icons/qr.svg'
import { ReactComponent as TimerIcon } from 'src/assets/icons/timer.svg'
import { ReactComponent as AppIcon } from 'src/assets/icons/app.svg'
import { ReactComponent as ModuleIcon } from 'src/assets/icons/modules.svg'
import { ReactComponent as LanguageIcon } from 'src/assets/icons/globe.svg'
import { ReactComponent as IntegrationIcon } from 'src/assets/icons/integration.svg'
import { roles, selectCurrentUserRole } from 'src/slices/users'
import { getSelectedLanguage } from 'src/store/general/selectors'
import translationsGlobal from 'src/translations/global'
import PagesIcon from 'react-feather/dist/icons/align-center'

const VenueInfo = () => {
  return ''
}

const sections = translations => {
  return [
    {
      subheader: translations.network,
      items: [
        {
          title: translations.overview,
          href: '/app/analytics',
          icon: OverviewIcon
        },
        {
          title: translations.venues,
          href: '/app/venues',
          icon: RestaurantIcon,
          info: VenueInfo
        },
        {
          title: translations.employees,
          href: '/app/staff',
          icon: UsersIcon
        },
        // {
        //   title: translations.programsOfLoyalty,
        //   href: '/app/loyalty',
        //   icon: StarIcon,
        //   disabled: true
        // },
        // {
        //   title: translations.promotions,
        //   href: '/app/loyalty',
        //   icon: StarIcon,
        //   disabled: true
        // },
        {
          title: translations.identity,
          href: '/app/identity',
          icon: TagIcon
        },
        {
          title: translations.reports,
          href: '/app/reports',
          icon: TagIcon
        }
        // {
        //   title: 'Настройки сети',
        //   href: '/app/brandSettings',
        //   icon: TagIcon
        // }
      ]
    },
    // {
    //   subheader: 'Заказы',
    //   items: [
    //     {
    //       title: 'Платежи',
    //       href: '/app/payments',
    //       icon: GroupIcon
    //     },
    //     {
    //       title: 'Клиенты',
    //       href: '/app/users',
    //       icon: GroupIcon
    //     }
    //   ]
    // },
    {
      subheader: translations.venue,
      items: [
        // {
        //   title: 'Активные меню',
        //   href: '/app/menu',
        //   icon: MenuBookIcon
        // },
        {
          title: translations.menu,
          href: '/app/multimenu',
          icon: RestaurantMenuIcon
        },
        // {
        //   title: 'Категории',
        //   href: '/app/categories',
        //   icon: IcCats
        // },
        {
          title: translations.dishes,
          href: '/app/dishes',
          icon: IcDishes
        },
        {
          title: translations.extraParameters,
          href: '/app/optionsets',
          icon: SlidersIcon
        },
        // {
        //   title: 'Опции',
        //   href: '/app/optionsets',
        //   icon: IcOptions
        // },
        {
          title: translations.modifiers,
          href: '/app/modifiers',
          icon: IcModifiers
        },
        {
          title: translations.lang,
          href: '/app/brandSettings',
          icon: LanguageIcon
        },
        {
          title: translations.copyright,
          href: '/app/copyright',
          icon: TagIcon
        },
        // {
        //   title: 'Настройки меню',
        //   href: '/app/menuSettings',
        //   icon: SettingsIcon,
        //   disabled: true
        // },
        // {
        //   title: translations.cookingTime,
        //   href: '/app/timeCalculator',
        //   icon: TimerIcon
        // },
        {
          title: translations.integrations,
          href: '/app/integration',
          icon: IntegrationIcon
        },
        {
          title: translations.qrGenerator,
          href: '/app/appSettings',
          icon: QrIcon
        }
      ]
    },
    {
      subheader: translations.guestApp,
      items: [
        {
          title: translations.appearance,
          href: '/app/client/ui/themes',
          icon: AppIcon
        },
        {
          title: translations.customPages,
          href: '/app/client/ui/pages',
          icon: PagesIcon
        }
        // {
        //   title: 'Шаблоны',
        //   href: '/app/client/templates',
        //   icon: GroupIcon,
        //   disabled: true
        // },
        // {
        //   title: translations.modules,
        //   href: '/app/client/modules',
        //   icon: ModuleIcon,
        //   disabled: true
        // }
      ]
    },
    {
      subheader: translations.adminPanel,
      role: roles.SUPERADMIN.key,
      items: [
        {
          title: translations.chooseBrand,
          href: '/app/brands/ui',
          icon: AppIcon
        }
      ]
    }
    // {
    //   subheader: 'Тестовые компоненты',
    //   items: [
    //     {
    //       title: 'Dashboard',
    //       icon: PieChartIcon,
    //       href: '/app/reports/dashboard'
    //     },
    //     {
    //       title: 'Dashboard alt',
    //       icon: PieChartIcon,
    //       href: '/app/reports/dashboard-alternative'
    //     }
    //   ]
    // }
    // {
    //   subheader: 'Pages',
    //   items: [
    //     {
    //       title: 'Account',
    //       href: '/app/account',
    //       icon: UserIcon
    //     },
    //     {
    //       title: 'Error',
    //       href: '/404',
    //       icon: AlertCircleIcon
    //     },
    //     {
    //       title: 'Pricing',
    //       href: '/pricing',
    //       icon: DollarSignIcon
    //     }
    //   ]
    // },
    // {
    //   subheader: 'Extra',
    //   items: [
    //     {
    //       title: 'Charts',
    //       href: '/app/extra/charts',
    //       icon: BarChartIcon,
    //       items: [
    //         {
    //           title: 'Apex Charts',
    //           href: '/app/extra/charts/apex'
    //         }
    //       ]
    //     },
    //     {
    //       title: 'Forms',
    //       href: '/app/extra/forms',
    //       icon: EditIcon,
    //       items: [
    //         {
    //           title: 'Formik',
    //           href: '/app/extra/forms/formik'
    //         },
    //         {
    //           title: 'Redux Forms',
    //           href: '/app/extra/forms/redux'
    //         }
    //       ]
    //     },
    //     {
    //       title: 'Editors',
    //       href: '/app/extra/editors',
    //       icon: LayoutIcon,
    //       items: [
    //         {
    //           title: 'DraftJS Editor',
    //           href: '/app/extra/editors/draft-js'
    //         },
    //         {
    //           title: 'Quill Editor',
    //           href: '/app/extra/editors/quill'
    //         }
    //       ]
    //     }
    // ]
    // }
  ]
}

function renderNavItems({ items, pathname, depth = 0, userRole }) {
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth, userRole }), [])}
    </List>
  )
}

function reduceChildRoutes({ acc, pathname, item, depth, userRole }) {
  const key = item.title + depth

  if (item.role && item.role !== userRole) {
    return ''
  }

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    })
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        disabled={item.disabled}
        role={item.role}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          userRole
        })}
      </NavItem>
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        disabled={item.disabled}
        role={item.role}
      />
    )
  }

  return acc
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}))

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles()
  const selectedLanguage = useSelector(getSelectedLanguage)
  const translations = translationsGlobal[selectedLanguage]
  const location = useLocation()
  const { user } = useAuth()
  const userRole = useSelector(selectCurrentUserRole)

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  const content = (
    <Box height='100%' display='flex' flexDirection='column'>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display='flex' justifyContent='center'>
            <RouterLink to='/'>
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        {/* <Box p={2}>
          <Box display='flex' justifyContent='center'>
            <RouterLink to='/app/account'>
              <Avatar alt='User' className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign='center'>
            <Link component={RouterLink} to='/app/account' variant='h5' color='textPrimary' underline='none'>
              <Typography variant='h5' color='textPrimary' underline='none'>
                {user.name}
              </Typography>
            </Link>
            <Typography variant='body2' color='textSecondary'>
              Your tier:{' '}
              <Link component={RouterLink} to='/app'>
                {user.tier}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Divider /> */}
        <Box p={2}>
          {sections(translations).map(section => {
            if (section.role && section.role !== userRole) return null
            return (
              <List
                key={section.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                  userRole
                })}
              </List>
            )
          })}
        </Box>
        {/* <Divider />
        <Box p={2}>
          <Box p={2} borderRadius='borderRadius' bgcolor='background.dark'>
            <Typography variant='h6' color='textPrimary'>
              Need Help?
            </Typography>
            <Link variant='subtitle1' color='secondary' component={RouterLink} to='/docs'>
              Check our docs
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor='left' classes={{ paper: classes.desktopDrawer }} open variant='persistent'>
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

export default NavBar
