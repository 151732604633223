import {
  GET_CURRENT_BRAND_STARTED,
  GET_CURRENT_BRAND_SUCCESS,
  GET_CURRENT_BRAND_ERROR,
  UPDATE_CURRENT_BRAND,
  UPDATE_CURRENT_BRAND_SUCCESS
} from './types'

export const getCurrentBrandSuccess = brand => ({
  type: GET_CURRENT_BRAND_SUCCESS,
  brand
})

export const getCurrentBrandError = reason => ({
  type: GET_CURRENT_BRAND_ERROR,
  errorMessage: reason
})

export const getCurrentBrandStarted = () => ({
  type: GET_CURRENT_BRAND_STARTED
})

export const updateCurrentBrand = updateBrandObj => ({
  type: UPDATE_CURRENT_BRAND,
  updateBrandObj
})

export const updateCurrentBrandSuccess = () => ({
  type: UPDATE_CURRENT_BRAND_SUCCESS
})
