import { createSelector, createSlice } from '@reduxjs/toolkit'
// import axios from 'axios'
import axios from 'src/utils/axios'
import { idsFromEntities } from 'src/utils/idsFromEntities'
import objFromArray from 'src/utils/objFromArray'
import { config } from 'src/constants'

const initialState = {
  entities: {},
  ids: [],
  loading: true,
  selectedBrand: null
}

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setBrands(state, action) {
      state.entities = objFromArray(action.payload, 'slug')
      state.ids = idsFromEntities({ entities: state.entities, key: 'slug' })
      state.loading = false
    },
    setSelectedBrand(state, action) {
      state.selectedBrand = action.payload
    }
  }
})

const entitiesToArr = state => {
  return state.ids.map(id => state.entities[id])
}

export const reducer = slice.reducer

export const { setBrands, setSelectedBrand } = slice.actions

const authHeader = {
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

export const fetchBrands = params => async dispatch => {
  const url = `${config.API_URL3}/admin/brands`
  const response = await axios.get(url, authHeader)
  // const response2 = await axios.get(url2, authHeader)

  console.log(response.data)

  // TODO: временный фикс, нужно в апи привести все данные к единому формату
  dispatch(slice.actions.setBrands(response.data))
}

export const selectBrandsSlice = state => state.brands

export const selectBrandsLoading = createSelector(selectBrandsSlice, x => x.loading)
export const selectBrandsEntities = createSelector(selectBrandsSlice, x => x.entities)
export const selectBrandOverload = createSelector(selectBrandsSlice, x => x.selectedBrand)

export const selectAllBrands = createSelector(selectBrandsSlice, entitiesToArr)
export const selectBrandById = id => createSelector(selectBrandsEntities, x => x[id])

export default slice
