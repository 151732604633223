import {
  GET_DISHES_STARTED,
  GET_DISHES_ERROR,
  GET_DISHES_SUCCESS,
  UPDATE_DISH_SUCCESS,
  UPDATE_DISH_STARTED,
  UPDATE_DISH_ERROR,
  DELETE_DISH_SUCCESS,
  DELETE_DISH_STARTED,
  DELETE_DISH_ERROR,
  ADD_DISH_SUCCESS,
  ADD_DISH_STARTED,
  ADD_DISH_ERROR
} from './Dishes.types'
import objFromArray from 'src/utils/objFromArray'

const initialState = {
  dishes: {},
  loading: false,
  errorMessage: null
}

const dishesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DISHES_SUCCESS: {
      return {
        ...state,
        dishes: objFromArray(action.dishes),
        loading: false
      }
    }
    case GET_DISHES_STARTED: {
      return {
        ...state,
        loading: true,
        errorMessage: null
      }
    }
    case GET_DISHES_ERROR: {
      return {
        ...state,
        loading: false,
        errorMessage: action.reason
      }
    }
    case UPDATE_DISH_SUCCESS: {
      const updatedDishes = {
        ...state.dishes,
        [action.dish.id]: {
          ...action.dish
        }
      }
      return {
        ...state,
        loading: false,
        dishes: updatedDishes
      }
    }
    case UPDATE_DISH_STARTED: {
      return {
        ...state,
        loading: true,
        errorMessage: null
      }
    }
    case UPDATE_DISH_ERROR: {
      return {
        ...state,
        loading: false,
        errorMessage: action.reason
      }
    }
    case ADD_DISH_SUCCESS: {
      return {
        ...state,
        loading: false,
        dishes: {
          ...state.dishes,
          [action.dish.id]: action.dish
        }
      }
    }
    case ADD_DISH_STARTED: {
      return {
        ...state,
        loading: true,
        errorMessage: null
      }
    }
    case ADD_DISH_ERROR: {
      return {
        ...state,
        loading: false,
        errorMessage: action.reason
      }
    }

    default:
      return state
  }
}

export default dishesReducer
