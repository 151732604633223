import { createSelector, createSlice } from '@reduxjs/toolkit'
// import axios from 'axios'
import { config } from 'src/constants'
import axios from 'src/utils/axios'
import { idsFromEntities } from 'src/utils/idsFromEntities'
import objFromArray from 'src/utils/objFromArray'

const initialState = {
  entities: {},
  ids: [],
  loading: true,
  currentUser: {}
}

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers(state, action) {
      state.entities = objFromArray(action.payload)
      state.ids = idsFromEntities({ entities: state.entities })
      state.loading = false
    },
    setUser(state, action) {
      state.currentUser = action.payload
    }
  }
})

const entitiesToArr = state => {
  return state.ids.map(id => state.entities[id])
}

export const reducer = slice.reducer

const authHeader = {
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

export const fetchUsers =
  (newBrand = null) =>
  async dispatch => {
    const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/users`
    const url1 = `${config.API_URL3}/backoffice/${window.busytable.brandSlug}/users/self`
    // const url2 = `${config.API_URL3}/admin/brands`
    const response = await axios.get(url, authHeader)
    const response1 = await axios.get(url1, authHeader)
    // const response2 = await axios.get(url2, authHeader)

    // TODO: временный фикс, нужно в апи привести все данные к единому формату
    const dataArr = Object.values(response.data.result).map(x => ({ ...x, id: x.userId }))

    dispatch(slice.actions.setUsers(dataArr))
    dispatch(slice.actions.setUser(response1.data))
  }

export const fetchUsersV3 =
  (newBrand = null) =>
  async dispatch => {
    const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/users`
    const url1 = `${config.API_URL3}/backoffice/${window.busytable.brandSlug}/users/self`
    // const url2 = `${config.API_URL3}/admin/brands`
    const response = await axios.get(url, authHeader)
    const response1 = await axios.get(url1, authHeader)
    // const response2 = await axios.get(url2, authHeader)

    // TODO: временный фикс, нужно в апи привести все данные к единому формату
    const dataArr = Object.values(response.data.result).map(x => ({ ...x, id: x.userId }))

    dispatch(slice.actions.setUsers(dataArr))
    dispatch(slice.actions.setUser(response1.data))
  }

export const approveUser = async (user, newBrand = null) => {
  if (!user.isUser) {
    const accessToken = window.localStorage.getItem('accessToken')
    const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/users/approve`
    const body = {
      userRole: user.isCandidate ? user.userRole.split('candidate_')[1] : 'candidate_' + user.userRole,
      name: user.name,
      email: user.email,
      phone: user.phone,
      userId: user.userId
    }
    const response = await axios.patch(url, body, authHeader).catch(e => {
      console.log(e.response)
      // return e
      throw new Error(e.response.status)
    })
    return response
  } else {
    return null
  }
}

export const roles = {
  USER: { key: 'user', value: 'Пользователь' },
  EX_ICON: { key: 'ex_icon', value: 'ex_icon' },
  OWNER: { key: 'owner', value: 'Владелец' },
  MANAGEMENT: { key: 'management', value: 'Менеджмент' },
  OFFICE: { key: 'office', value: 'Сотрудник офиса' },
  FRIEND: { key: 'friend', value: 'Друзья ресторана' },
  MANAGER: { key: 'manager', value: 'Менеджер ресторана' },
  CASHIER: { key: 'cashier', value: 'Кассир' },
  RUNNER: { key: 'runner', value: 'Раннер / Упаковщик' },
  WAITER: { key: 'waiter', value: 'Официант' },
  BAR: { key: 'bar', value: 'Бариста' },
  KITCHEN: { key: 'kitchen', value: 'Повар' },
  ADMIN: { key: 'admin', value: 'Администратор' },
  SUPERADMIN: { key: 'superadmin', value: 'Суперадмин' }
}

export const selectUsersSlice = state => state.users

export const selectUsersLoading = createSelector(selectUsersSlice, x => x.loading)
export const selectUsersEntities = createSelector(selectUsersSlice, x => x.entities)
export const selectCurrentUser = createSelector(selectUsersSlice, x => x.currentUser)

export const isSuperAdmin = createSelector(
  selectUsersSlice,
  x => x.currentUser && x.currentUser.userRole === 'superadmin'
)

export const selectCurrentUserRole = createSelector(selectUsersSlice, x =>
  x.currentUser && x.currentUser.userRole ? x.currentUser.userRole : ''
)

export const selectAllUsers = createSelector(selectUsersSlice, entitiesToArr)
export const selectUserById = id => createSelector(selectUsersEntities, x => x[id])

export default slice
