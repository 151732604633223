import { Box, Breadcrumbs, Button, Grid, IconButton, Link, makeStyles, SvgIcon, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  MoreVertical as MoreIcon,
  Smartphone as MobileIcon,
  PlusCircle as PlusCircleIcon,
  X as CancelIcon,
  Calendar,
  Upload as UploadIcon,
  Download as DownloadIcon
} from 'react-feather'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'sticky',
    top: '0px',
    backgroundColor: theme.palette.background.dark,
    zIndex: 3
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  },
  activeButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.default,
    '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.secondary.main }
  }
}))

const Header = ({
  titleSection,
  subTitleSection,
  breadcrumbStep1,
  breadcrumbStep2,
  breadcrumbStep3,
  breadcrumbStep1link,
  breadcrumbStep2link,
  breadcrumbStep3link,
  modified,
  importButton,
  exportButton,
  labelButton,
  linkButton,
  callSubmit,
  isPreview,
  isPreview2,
  showPreview,
  showPreview2,
  selectedComponent,
  callReset,
  saved,
  create,
  updatedCountString,
  toggleSaved,
  className,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Grid className={clsx(classes.root, className)} container justify='space-between' spacing={3} {...rest}>
      <Grid item>
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
          <Link variant='body1' color='inherit' to={breadcrumbStep1link} component={RouterLink}>
            {breadcrumbStep1}
          </Link>
          {breadcrumbStep2link ? (
            <Link variant='body1' color='inherit' to={breadcrumbStep2link} component={RouterLink}>
              {breadcrumbStep2}
            </Link>
          ) : (
            ''
          )}
          {breadcrumbStep3link ? (
            <Link
              variant='body1'
              color='inherit'
              to={`${breadcrumbStep3link}${breadcrumbStep3.id}`}
              component={RouterLink}
            >
              {breadcrumbStep3.name}
            </Link>
          ) : (
            ''
          )}
        </Breadcrumbs>
        <Typography variant='h3' color='textPrimary'>
          {titleSection}
        </Typography>
        {importButton && exportButton ? (
          <Box mt={2}>
            <Button
              startIcon={
                <SvgIcon fontSize='small'>
                  <UploadIcon />
                </SvgIcon>
              }
            >
              {importButton}
            </Button>
            <Button
              startIcon={
                <SvgIcon fontSize='small'>
                  <DownloadIcon />
                </SvgIcon>
              }
            >
              {exportButton}
            </Button>
          </Box>
        ) : (
          ''
        )}
        {subTitleSection ? (
          typeof subTitleSection == 'string' ? (
            <Typography variant='h3' color='textPrimary'>
              {subTitleSection}
            </Typography>
          ) : (
            <Typography variant='h3' color='textPrimary'>
              {subTitleSection ? `${subTitleSection.firstname || ''} ${subTitleSection.lastname || ''}` : ''}
            </Typography>
          )
        ) : (
          ''
        )}
        {breadcrumbStep2link && modified ? (
          <Typography className={classes.subTitle} variant='subtitle1' color='textSecondary'>
            <div className={classes.updatedAtWrapper}>
              <Calendar size='15' />
              <span className={classes.updatedAtLabel}>
                {modified} {moment(subTitleSection.updated_at).format('DD.MM.YY')}
              </span>
            </div>
          </Typography>
        ) : (
          ''
        )}
      </Grid>
      {callSubmit || linkButton ? (
        <Grid item>
          <Grid container spacing={2} direction='row' justify='flex-end' alignItems='center'>
            <Grid item>
              {isPreview2 ? (
                <IconButton
                  onClick={() => showPreview2(!isPreview2)}
                  className={clsx(isPreview2 && classes.activeButton)}
                >
                  <SvgIcon fontSize='small'>
                    <MobileIcon />
                  </SvgIcon>
                </IconButton>
              ) : isPreview ? (
                <IconButton
                  onClick={() => showPreview(!!isPreview ? false : isPreview)}
                  className={clsx(isPreview && classes.activeButton)}
                >
                  <SvgIcon fontSize='small'>
                    <MobileIcon />
                  </SvgIcon>
                </IconButton>
              ) : (
                ''
              )}
              {callReset ? (
                <IconButton
                  disabled={saved}
                  onClick={() => {
                    callReset()
                  }}
                >
                  <SvgIcon fontSize='small'>
                    <CancelIcon />
                  </SvgIcon>
                </IconButton>
              ) : (
                ''
              )}
            </Grid>

            <Grid item>
              {callSubmit ? (
                <Button
                  disabled={saved}
                  color='secondary'
                  variant='contained'
                  onClick={() => {
                    callSubmit()
                  }}
                >
                  {labelButton}
                  {updatedCountString}
                </Button>
              ) : create === true ? (
                <Button
                  color='secondary'
                  variant='contained'
                  component={RouterLink}
                  to={linkButton}
                  startIcon={
                    <SvgIcon fontSize='small'>
                      <PlusCircleIcon />
                    </SvgIcon>
                  }
                >
                  {labelButton}
                </Button>
              ) : (
                <Button color='secondary' variant='contained' component={RouterLink} to={linkButton}>
                  {labelButton}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string
}

export default Header
