import {
  createCategoryError,
  createCategoryStarted,
  createCategorySuccess,
  deleteCategoryError,
  deleteCategoryStarted,
  deleteCategorySuccess,
  getCategoriesError,
  getCategoriesStarted,
  getCategoriesSuccess,
  updateCategoryError,
  updateCategoryStarted,
  updateCategorySuccess
} from './Categories.actions'
import { request } from 'src/api/request'

export const getCategoriesThunk = () => async dispatch => {
  try {
    dispatch(getCategoriesStarted())
    const response = await request('/categories')
    dispatch(getCategoriesSuccess(response || []))
  } catch (error) {
    dispatch(getCategoriesError(error.message))
  }
}

export const createCategoryThunk = category => async dispatch => {
  try {
    dispatch(createCategoryStarted(category))
    const createdCategory = await request('/categories', {
      method: 'POST',
      data: category
    })

    dispatch(createCategorySuccess(createdCategory))
  } catch (error) {
    dispatch(createCategoryError(error.message))
  }
}

export const updateCategoryThunk = category => async dispatch => {
  try {
    dispatch(updateCategoryStarted(category.id))
    const updatedCategory = await request('/categories', {
      method: 'PATCH',
      path: `/${category.id}`,
      data: category
    })

    dispatch(updateCategorySuccess(updatedCategory))
  } catch (error) {
    dispatch(updateCategoryError(error.message))
  }
}

export const deleteCategoryThunk = categoryId => async dispatch => {
  try {
    dispatch(deleteCategoryStarted(categoryId))
    await request('/categories', {
      method: 'DELETE',
      data: {
        id: categoryId
      }
    })

    dispatch(deleteCategorySuccess(categoryId))
  } catch (error) {
    dispatch(deleteCategoryError(error.message))
  }
}
