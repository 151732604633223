import {
  GET_ACTIVE_THEME_STARTED,
  GET_ACTIVE_THEME_SUCCESS,
  GET_ACTIVE_THEME_ERROR,
  SET_ACTIVE_THEME,
  GET_ALL_THEMES
} from './Themes.types'

export const getActiveThemeStarted = data => {
  return {
    type: GET_ACTIVE_THEME_STARTED,
    data
  }
}

export const getActiveThemeSuccess = resp => {
  return {
    type: GET_ACTIVE_THEME_SUCCESS,
    resp
  }
}

export const getActiveThemeError = error => {
  return {
    type: GET_ACTIVE_THEME_ERROR,
    error
  }
}

export const setActiveTheme = theme => {
  return {
    type: SET_ACTIVE_THEME,
    theme
  }
}
export const getAllThemes = themes => {
  return {
    type: GET_ALL_THEMES,
    themes
  }
}
