import axios from 'src/utils/axios'
import { config } from 'src/constants'
import { sessionsEndpointUrl, themesEndpointUrl, translationsEndpoint } from 'src/config'

export const authHeader = {
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

export const fetchListOfVenues = async () => {
  return axios
    .get(`${config.API_URL3}/backoffice/${window.busytable.brandSlug}/venues`)
    .then(e => {
      return e.data
    })
    .catch(e => {
      throw new Error(e)
    })
}

export const postTokenRegister = async token => {
  return axios
    .post(
      `${config.API_URL3}/${window.busytable.brandSlug}/auth/token/set`,
      {},
      {
        withCredentials: true,
        headers: { token, 'x-url': window.location.href, verified: 'yes' }
      }
    )
    .then(resp => {
      return resp
    })
    .catch(err => {
      localStorage.removeItem('__auth_token')
      return { status: 'fail' }
    })
}

export const fetchSelfProfile = () => {
  const url = `${config.API_URL3}/backoffice/${window.busytable.brandSlug}/users/self`
  return axios
    .get(url, authHeader)
    .then(resp => {
      return resp.data
    })
    .catch(err => {
      console.log(err)
    })
}

export const deleteToken = async () => {
  return axios({
    url: `${config.API_URL3}/${window.busytable.brandSlug}/auth/token/del`,
    method: 'DELETE',
    ...authHeader
  })
    .then(resp => {
      if (resp) {
        return resp
      }
    })
    .catch(e => {
      return e
    })
}

export const updateUser = async (updatedUserDto, newBrand = null, userId) => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/users/${userId}`
  return axios
    .post(url, updatedUserDto, authHeader)
    .then(res => {
      return res.data
    })
    .catch(e => {
      throw new Error(e)
    })
}

export const fetchCurrentBrand = async () => {
  const url = `${config.API_URL3}/backoffice/${window.busytable.brandSlug}/brands/current`
  const response = await axios.get(url, authHeader)
  if (response?.data) {
    return response?.data
  }
  return null
}

export const fetchBrandCustomizationSettings = async () => {
  const url = `${config.API_URL3}/backoffice/${window.busytable.brandSlug}/brands/customization-settings`
  const response = await axios.get(url, authHeader)
  if (response?.data) {
    return response?.data
  }
  return null
}

export const fetchUsers = async newBrand => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/users`
  const response = await axios.get(url, authHeader)
  if (response?.data?.result) {
    return response?.data?.result
  }
  return []
}

export const getUserById = async (userId, newBrand) => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/users/${userId}`
  return axios
    .get(url, authHeader)
    .then(resp => {
      if (resp?.data) {
        return resp.data
      }
    })
    .catch(e => {
      return e
    })
}

export const createNewSessionByUserId = async userId => {
  return axios
    .post(`${sessionsEndpointUrl}/${userId}`, {}, authHeader)
    .then(resp => {
      if (resp?.data) {
        return resp.data
      }
    })
    .catch(e => {
      return e
    })
}

export const createNewSessionAndDeletePrevious = async userId => {
  return axios
    .get(`${sessionsEndpointUrl}/${userId}/delete_all_and_create_new`, authHeader)
    .then(resp => {
      if (resp?.data) {
        return resp.data
      }
    })
    .catch(e => {
      return e
    })
}

export const getSessionsByUserId = async userId => {
  return axios
    .get(`${sessionsEndpointUrl}/${userId}`, authHeader)
    .then(resp => {
      if (resp?.data) {
        return resp?.data?.data
      }
    })
    .catch(e => {
      return e
    })
}

export const deleteAllSessionsByUserId = async userId => {
  return axios
    .delete(`${sessionsEndpointUrl}/${userId}/all`, authHeader)
    .then(resp => {
      if (resp?.data) {
        return resp?.data
      }
    })
    .catch(e => {
      return e
    })
}
/**
 *
 * @param {string} id / Session Id param
 */
export const deleteSessionById = async id => {
  return axios
    .delete(`${sessionsEndpointUrl}/${id}`, authHeader)
    .then(resp => {
      if (resp?.data) {
        return resp?.data
      }
    })
    .catch(e => {
      throw new Error(e)
    })
}

export const createThemeTemplate = async input => {
  return axios
    .post(themesEndpointUrl, input, authHeader)
    .then(resp => {
      if (resp?.data) {
        return resp.data
      }
    })
    .catch(e => {
      return e
    })
}

export const getThemesQuery = async () => {
  return axios
    .get(themesEndpointUrl, authHeader)
    .then(resp => {
      if (resp?.data) {
        return resp.data
      }
    })
    .catch(e => {
      return e
    })
}

export const getCurrentThemeQuery = async () => {
  return axios
    .get(`${themesEndpointUrl}/current`, authHeader)
    .then(resp => {
      if (resp?.data) {
        return resp.data
      }
    })
    .catch(e => {
      return e
    })
}

export const getLanguages = async () => {
  try {
    const resp = await axios.get(`${translationsEndpoint}/languages`, authHeader)
    if (resp?.data) {
      return resp.data
    }
  } catch (e) {
    console.log(`Eror while loading languages: ${e.message}`)
  }
}

export const editLanguagesList = async newLanguagesList => {
  try {
    const resp = await axios.put(`${translationsEndpoint}/languages`, newLanguagesList, authHeader)
    if (resp?.data) {
      return resp.data
    }
  } catch (e) {
    console.log(`Eror while updating languages: ${e.message}`)
  }
}
