import {
  GET_ACTIVE_THEME_STARTED,
  GET_ACTIVE_THEME_SUCCESS,
  GET_ACTIVE_THEME_ERROR,
  SET_ACTIVE_THEME,
  GET_ALL_THEMES
} from './Themes.types'

const themesInitialState = {
  currentActiveTheme: null,
  themes: [],
  isLoading: false,
  error: null
}

export const ThemesReducer = (state = themesInitialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_THEME_STARTED: {
      return {
        ...state,
        isLoading: true
      }
    }

    case GET_ACTIVE_THEME_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        currentActiveTheme: action.resp
      }
    }

    case GET_ACTIVE_THEME_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    }

    case SET_ACTIVE_THEME: {
      return {
        ...state,
        currentActiveTheme: action.theme
      }
    }

    case GET_ALL_THEMES: {
      return {
        ...state,
        themes: action.themes
      }
    }

    default: {
      return state
    }
  }
}
