import {
  ADD_DISH_ERROR,
  ADD_DISH_STARTED,
  ADD_DISH_SUCCESS,
  DELETE_DISH_ERROR,
  DELETE_DISH_STARTED,
  DELETE_DISH_SUCCESS,
  GET_DISHES_ERROR,
  GET_DISHES_STARTED,
  GET_DISHES_SUCCESS,
  UPDATE_DISH_ERROR,
  UPDATE_DISH_STARTED,
  UPDATE_DISH_SUCCESS
} from './Dishes.types'

export const getDishesSuccess = dishes => ({
  type: GET_DISHES_SUCCESS,
  dishes
})

export const getDishesStarted = () => ({
  type: GET_DISHES_STARTED
})

export const getDishesError = reason => ({
  type: GET_DISHES_ERROR,
  reason
})

export const updateDishSuccess = updatedDishObj => ({
  type: UPDATE_DISH_SUCCESS,
  dish: updatedDishObj
})

export const updateDishStarted = id => ({
  type: UPDATE_DISH_STARTED,
  id
})

export const updateDishError = reason => ({
  type: UPDATE_DISH_ERROR,
  reason
})

export const deleteDishSuccess = id => ({
  type: DELETE_DISH_SUCCESS,
  id
})

export const deleteDishStarted = id => ({
  type: DELETE_DISH_STARTED,
  id
})

export const deleteDishError = reason => ({
  type: DELETE_DISH_ERROR,
  reason
})

export const createDishSuccess = dish => ({
  type: ADD_DISH_SUCCESS,
  dish
})

export const createDishStarted = dish => ({
  type: ADD_DISH_STARTED,
  dish
})

export const createDishError = reason => ({
  type: ADD_DISH_ERROR,
  reason
})
