const translationsDishes = {
  ru: {
    venue: 'Ресторан',
    dishes: 'Блюда',
    dish: 'блюдо',
    findDish: 'Поиск блюда',
    sort: 'Сортировка',
    delete: 'Удалить',
    addedTo: 'Добавить в',
    addToPopular: 'Добавить в популярные',
    removeFromPopular: 'Удалить из популярных',
    transferTo: 'Переместить в',
    name: 'Название',
    price: 'Цена',
    startEnterName: 'Начните вводить название',
    alphabetically: 'По алфавиту (А-Я)',
    alphabeticallyReverse: 'По алфавиту (Я-А)',
    byAddingFromRecent: 'По добавлению (от последних)',
    byAddingFromFirst: 'По добавлению (от первых)',
    byPriceFromExpensive: 'По цене (от дорогих)',
    byPriceFromCheap: 'По цене (от дешевых)',
    byPopular: 'Популярные',
    all: 'Все',
    listDishes: 'Список блюд',
    newDish: 'Новое блюдо',
    addedDish: 'Добавить блюдо',
    save: 'Сохранить',
    errorInForm: 'В форме присутствуют ошибки',
    refreshDish: 'Блюдо обновлено',
    errorHasOccurred: 'Произошла ошибка',
    dishWasAdd: 'Блюдо добавлено',
    notAllTranslationsAdded: 'Не введены все переводы!',
    atMenu: 'В меню',
    nameOfMenu: 'Названия меню',
    atCategories: 'В категориях',
    nameOfCategories: 'Названия категорий',
    atAction: 'В акциях',
    nameOfAction: 'Названия акций',
    guestSees: 'Гость видит',
    cashierSees: 'Кассир видит',
    descDishForGuest: 'Описание блюда для гостя',
    calories: 'Каллории',
    fats: 'Жиры',
    protein: 'Белки',
    carbohydrates: 'Углеводы',
    media: 'Медиа',
    imageInOpenDish: 'Изображение в открытом блюде',
    pathToImage: 'Путь к изображению',
    formatOfImage: 'Изображение в форматах PNG или JPG, желательный размер – не менее 1200px по большой стороне.',
    imageInList: 'Изображение в списке',
    formatOfImagePreview:
      'Картинка формата PNG или JPG, или видео в H.264, OGG, WEBM или GIF. Желательное разрешение 1200х1200.',
    options: 'Опции',
    enterKeyWord: 'Введите ключевое слово',
    waitingTime: 'Время ожидания',
    volume: 'Выход',
    unit: 'Единицы измерения',
    unit_g: 'г',
    unit_pc: 'шт',
    unit_ml: 'мл',
    unit_kg: 'кг',
    days: 'Дни',
    hours: 'Часы',
    minutes: 'Минуты',
    attachDish: 'Прикрепить блюдо',
    selectParentDish: 'Выбрать родительское блюдо',
    sizeName: 'Название размера',
    firstPosition: 'Первая позиция',
    secondPosition: 'Вторая позиция',
    childDishesHeader: 'Список дочерних блюд и название размера',
    preorderStart: 'Начало',
    preorderEnd: 'Конец',
    preorderInfo: 'Предзаказ',
    tags: 'Теги',
    tagsEditor: 'Редактор тегов',
    create: 'Создать',
    cancel: 'Отменить',
    createTag: 'Создать тег',
    editTag: 'Редактировать тег',
    deleteTagConfirm: 'Вы действительно хотите удалить данный тег?',
    no: 'Нет',
    yes: 'Да',
    edit: 'Изменить',
    selectTagColor: 'Выберите цвет тега',
    tagPriority: 'Приоритет тега',
    tagVisibility: 'Видимость тега',
    tagCreated: 'Тег создан',
    tagUpdated: 'Тег обновлен',
    visible: 'Видимий',
    otherSettings: "Другие настройки",
    involvedInFiltering: "Участвует в фильтрации",
    hidden: 'Скрытый',
    slug: 'Идентификатор'
  },
  uk: {
    venue: 'Ресторан',
    dishes: 'Страви',
    dish: 'Страва',
    findDish: 'Пошук страви',
    sort: 'Сортування',
    delete: 'Видалити',
    addedTo: 'Додати до',
    addToPopular: 'Додати до популярних',
    removeFromPopular: 'Видалити з популярних',
    transferTo: 'Перемістити до',
    name: 'Назва',
    price: 'Ціна',
    startEnterName: 'Почніть вводити назву',
    alphabetically: 'За алфавітом (А-Я)',
    alphabeticallyReverse: 'За алфавітом (Я-А)',
    byAddingFromRecent: 'По додаванню (від останніх)',
    byAddingFromFirst: 'По додаванню (від перших)',
    byPriceFromExpensive: 'За ціною (від дорогих)',
    byPriceFromCheap: 'За ціною (від дешевих)',
    byPopular: 'Популярні',
    all: 'Усі',
    listDishes: 'Список страв',
    newDish: 'Нова страва',
    addedDish: 'Додати страву',
    save: 'Зберегти',
    errorInForm: 'У формі присутні помилки',
    refreshDish: 'Страву оновлено',
    errorHasOccurred: 'Сталася помилка',
    dishWasAdd: 'Страву додано',
    notAllTranslationsAdded: 'Не всі переклади додани!',
    atMenu: 'В меню',
    nameOfMenu: 'Назва меню',
    atCategories: 'У категоріях',
    nameOfCategories: 'Назва категорій',
    atAction: 'В акціях',
    nameOfAction: 'Назви акцій',
    guestSees: 'Гість бачить',
    cashierSees: 'Касир бачить',
    descDishForGuest: 'Опис страви для гостя',
    calories: 'Калорії',
    fats: 'Жири',
    protein: 'Білки',
    carbohydrates: 'Вуглеводи',
    media: 'Медіа',
    imageInOpenDish: 'Зображення у відкритому блюді',
    pathToImage: 'Шлях до зображення',
    formatOfImage: 'Зображення в форматах PNG або JPG, бажаний розмір - не менше 1200px по великій стороні.',
    imageInList: 'Зображення у списку',
    formatOfImagePreview:
      'Зображення формату PNG або JPG, або відео в H.264, OGG, WEBM або GIF. Бажаний розмір 1200х1200. ',
    options: 'Опції',
    enterKeyWord: 'Введіть ключове слово',
    waitingTime: 'Час очікування',
    volume: 'Вихід',
    unit: 'Одиниці виміру',
    unit_g: 'г',
    unit_pc: 'шт',
    unit_ml: 'мл',
    unit_kg: 'кг',
    days: 'Дні',
    hours: 'Години',
    minutes: 'Хвилини',
    attachDish: 'Прикріпити страву',
    selectParentDish: 'Вибрати батьківську страву',
    sizeName: 'Назва розміру',
    firstPosition: 'Перша позиція',
    secondPosition: 'Друга позиція',
    childDishesHeader: 'Список дочірніх страв та назва розміру',
    preorderStart: 'Початок',
    preorderEnd: 'Кінець',
    preorderInfo: 'Передзамовлення',
    tags: 'Теги',
    tagsEditor: 'Редактор тегів',
    createTag: 'Створити тег',
    create: 'Створити',
    cancel: 'Скасувати',
    editTag: 'Редагувати тег',
    deleteTagConfirm: 'Ви дійсно хочете видалити даний тег?',
    no: 'Ні',
    yes: 'Так',
    edit: 'Змінити',
    selectTagColor: 'Виберіть колір тегу',
    tagPriority: 'Пріоритет тегу',
    tagVisibility: 'Видимість тегу',
    tagCreated: 'Тег створено',
    tagUpdated: 'Тег оновлено',
    visible: 'Видимий',
    otherSettings: "Інші налаштування",
    involvedInFiltering: "Використовувати у фільтрації",
    hidden: 'Прихований',
    slug: 'Ідентифікатор'
  }
}
export default translationsDishes
