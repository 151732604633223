import { createSelector, createSlice } from '@reduxjs/toolkit'
// import axios from 'axios'
import { config } from 'src/constants'
import axios from 'src/utils/axios'
import { idsFromEntities } from 'src/utils/idsFromEntities'
import objFromArray from 'src/utils/objFromArray'

const initialState = {
  entities: {},
  ids: [],
  original: [],
  loading: true
}

const entitiesToArr = state => {
  return state.ids.map(id => state.entities[id])
}

const slice = createSlice({
  name: 'optionsets',
  initialState,
  reducers: {
    setOptionsets(state, action) {
      state.entities = objFromArray(action.payload)
      state.ids = idsFromEntities({ entities: state.entities })
      state.loading = false
    }
  }
})

export const reducer = slice.reducer

const authHeader = {
  withCredentials: true,
  headers: { 'x-url': window.location.href, verified: 'yes', 'Content-Type': 'application/json' }
}

export const fetchOptionsets = (newBrand = null) => async dispatch => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/optionsets`
  const response = await axios.get(url, authHeader)

  dispatch(slice.actions.setOptionsets(response.data.result))
}

export const postOptionsetApi = async (value, newBrand = null) => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/optionsets`
  const body = value
  const response = await axios
    .post(url, body, authHeader)
    .then(e => e)
    .catch(err => ({ status: 'fail' }))
  return response
}

export const patchOptionsetApi = async (value, newBrand = null) => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/optionsets/${value.id}`
  const body = value
  const response = await axios
    .patch(url, body, authHeader)
    .then(e => e)
    .catch(err => ({ status: 'fail' }))
  return response
}

export const deleteOptionsetApi = async (value, newBrand = null) => {
  const url = `${config.API_URL3}/backoffice/${newBrand ? newBrand : window.busytable.brandSlug}/optionsets/${value.id}`
  const response = await axios
    .delete(url, authHeader)
    .then(e => e)
    .catch(err => ({ status: 'fail' }))
  return response
}

export const selectOptionsetSlice = state => state.optionsets

export const selectOptionsetLoading = createSelector(selectOptionsetSlice, x => x.loading)

export const selectAllOptionsets = createSelector(selectOptionsetSlice, entitiesToArr)
export const selectAllOptionsetsEntities = createSelector(selectOptionsetSlice, x => x.entities)
export const selectOptionsetById = id => createSelector(selectAllOptionsetsEntities, x => x[id])

export default slice
