import {
  GET_CATEGORIES_STARTED,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_SUCCESS,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_STARTED,
  UPDATE_CATEGORY_ERROR,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_STARTED,
  DELETE_CATEGORY_ERROR,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_STARTED,
  ADD_CATEGORY_ERROR,
  RESET_CATEGORY_EDITING
} from './Categories.types'
import objFromArray from 'src/utils/objFromArray'

const initialState = {
  categories: {},
  loading: false,
  errorMessage: null,
  lastDelete: false,
  editing: {
    result: false,
    loading: false,
    errorMessage: null
  }
}

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: objFromArray(action.categories),
        loading: false
      }
    }
    case GET_CATEGORIES_STARTED: {
      return {
        ...state,
        loading: true,
        errorMessage: null
      }
    }
    case GET_CATEGORIES_ERROR: {
      return {
        ...state,
        loading: false,
        errorMessage: action.reason
      }
    }
    case DELETE_CATEGORY_SUCCESS: {
      const updatedCategories = { ...state.categories }
      delete updatedCategories[action.id]

      return {
        ...state,
        categories: updatedCategories,
        loading: false,
        lastDelete: true
      }
    }
    case DELETE_CATEGORY_STARTED: {
      return {
        ...state,
        loading: true,
        errorMessage: null,
        lastDelete: false
      }
    }
    case DELETE_CATEGORY_ERROR: {
      return {
        ...state,
        loading: false,
        errorMessage: action.reason
      }
    }
    case UPDATE_CATEGORY_SUCCESS: {
      const updatedCategories = {
        ...state.categories,
        [action.category.id]: {
          ...action.category
        }
      }
      return {
        ...state,
        categories: updatedCategories,
        editing: {
          loading: false,
          result: true,
          errorMessage: null
        }
      }
    }
    case UPDATE_CATEGORY_STARTED: {
      return {
        ...state,
        editing: {
          loading: true,
          result: false,
          errorMessage: null
        }
      }
    }
    case UPDATE_CATEGORY_ERROR: {
      return {
        ...state,
        loading: false,
        editing: {
          loading: false,
          errorMessage: action.reason,
          result: false
        }
      }
    }
    case ADD_CATEGORY_SUCCESS: {
      return {
        ...state,
        categories: {
          ...state.categories,
          [action.category.id]: action.category
        },
        editing: {
          loading: false,
          errorMessage: null,
          result: true
        }
      }
    }
    case ADD_CATEGORY_STARTED: {
      return {
        ...state,
        editing: {
          loading: true,
          errorMessage: null,
          result: false
        }
      }
    }
    case ADD_CATEGORY_ERROR: {
      return {
        ...state,
        editing: {
          result: false,
          loading: false,
          errorMessage: action.reason
        }
      }
    }
    case RESET_CATEGORY_EDITING: {
      return {
        ...state,
        editing: {
          errorMessage: null,
          result: false,
          loading: false
        }
      }
    }

    default:
      return state
  }
}

export default categoriesReducer
