import { GET_USERS_SUCCESS, UPDATE_USER_BY_ID, GET_USERS_STARTED } from './types'

const initialState = {
  users: [],
  isLoading: false,
  currentUser: {}
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.users,
        isLoading: false
      }
    }

    case GET_USERS_STARTED: {
      return {
        ...state,
        isLoading: true
      }
    }
    case UPDATE_USER_BY_ID: {
      const updatedUsers = state.users.map(user => {
        if (user.userId === action.userId) {
          return {
            ...user,
            ...action.updatedUserObj
          }
        }
        return user
      })
      return {
        ...state,
        users: updatedUsers
      }
    }

    default:
      return state
  }
}

export default usersReducer
