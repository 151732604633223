import { SET_SELECTED_LANGUAGE } from './types'

export const initialState = {
  selectedLanguage: 'uk'
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_LANGUAGE: {
      return {
        ...state,
        selectedLanguage: action.selectedLanguage
      }
    }

    default:
      return state
  }
}
