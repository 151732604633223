import { request } from 'src/api/request'
import {
  getTagsStarted,
  getTagsError,
  getTagsSuccess,
  createTagSuccess,
  createTagStarted,
  createTagError,
  updateTagSuccess,
  updateTagError,
  updateTagStarted,
  deleteTagSuccess,
  deleteTagError,
  deleteTagStarted
} from './tags.actions'

export const getTagsThunk = () => async dispatch => {
  try {
    dispatch(getTagsStarted())
    const response = await request('/tags')

    dispatch(getTagsSuccess(response.data))
  } catch (error) {
    dispatch(getTagsError(error.message))
  }
}

export const createTagThunk = tag => async dispatch => {
  try {
    dispatch(createTagStarted(tag))
    const createdTag = await request('/tags', {
      method: 'POST',
      data: tag
    })

    dispatch(createTagSuccess(createdTag.data))
  } catch (error) {
    dispatch(createTagError(error.message))
  }
}

export const updateTagThunk = (tagId, data) => async dispatch => {
  try {
    dispatch(updateTagStarted(tagId))
    const updatedTag = await request('/tags', {
      method: 'PATCH',
      data,
      path: `/${tagId}`
    })

    dispatch(updateTagSuccess(updatedTag.data))
  } catch (error) {
    dispatch(updateTagError(error.message))
  }
}

export const deleteTagThunk = tagId => async dispatch => {
  try {
    dispatch(deleteTagStarted(tagId))
    await request('/tags', {
      method: 'DELETE',
      path: `/${tagId}`
    })

    dispatch(deleteTagSuccess(tagId))
  } catch (error) {
    dispatch(deleteTagError(error.message))
  }
}
